import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//ethereum
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import genericErc721Abi from "../../assets/lib/erc721Abi";

import styles from "assets/jss/material-kit-react/components/erc20listStyle.js";

const useStyles = makeStyles(styles);

function formatId(id) {
  return id.length > 10 ? id.substr(0, 4) + "..." + id.substr(-4) : id;
}

function formatAddress(addr) {
  return addr.substr(0, 6) + "..." + addr.substr(-5);
}

export default function DisplayErc721({ address, id }) {
  const classes = useStyles();

  const listClasses = classNames(classes.collectionItem);
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const imageClasses = classNames(classes.imgFluid);
  const web3ReactAlwaysOn = useWeb3React("alwaysOn");
  const [erc721, setErc721] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  //Get more info through TokenURI
  async function getTokenURI() {
    if (erc721) return;
    var erc721Temp = { address: address, id: id };

    let chain = null;
    switch (web3ReactAlwaysOn.chainId) {
      // case 1:
      //   chain = "0x1";
      //   break;
      // case 4:
      //   chain = "0x4";
      //   break;
      case 5:
        chain = "0x5";
        break;
      case 137:
        chain = "0x89";
        break;
      // case 80001:
      //   chain = "mumbai";
      //   break;
      default:
        console.log("Wallet chain unknown", "danger");
        return;
    }

    const response = await axios.get(
      "https://deep-index.moralis.io/api/v2/nft/" +
        address +
        "/" +
        id +
        "?chain=" +
        chain +
        "&format=decimal",
      {
        headers: {
          "X-API-KEY":
            "4GNFZC0VFXMtQLwWchYtvPgPjGJznGYdBF11AEx162jK1hU8SokCmg8IRhhwz7iR",
        },
      }
    );

    let result = response?.data;
    if (!result) return console.log("Not found on moralis");
    if (result.metadata) result.metadata = JSON.parse(result.metadata);

    //manage exceptions :
    //IPFS format :
    if (
      result.metadata?.image &&
      result.metadata.image.substring(0, 4) == "ipfs"
    ) {
      result.metadata.image =
        "https://ipfs.io/" + result.metadata.image.split("//")[1];
    }

    if (result.metadata?.animation_url) {
      result.metadata.media = { uri: result.metadata?.animation_url };
      if (result.metadata.media.uri.substring(0, 4) == "ipfs")
        result.metadata.media.uri =
          "https://ipfs.io/" + result.metadata.media.uri.split("//")[1];
    }

    console.log(result.metadata);

    // TODO: Get from ipfs, how to ? without ipfs gateway
    if (!result?.metadata && result?.token_uri) {
      const responseTokenUri = await axios.get(result?.token_uri);
      let resultTokenUri = responseTokenUri?.data;

      if (!resultTokenUri) {
        console.log("Token URI Not found on moralis");
      } else {
        if (resultTokenUri?.image) {
          result.metadata = {
            ...resultTokenUri,
            image: resultTokenUri?.image.replace(
              "ipfs://ipfs/",
              "https://ipfs.moralis.io:2053/ipfs/"
            ),
          };
        }
        if (resultTokenUri?.animation_url) {
          result.metadata = {
            ...resultTokenUri,
            media: {
              uri: resultTokenUri?.animation_url.replace(
                "ipfs://ipfs/",
                "https://ipfs.moralis.io:2053/ipfs/"
              ),
            },
          };
        }
      }
    }

    erc721Temp = result;

    setErc721(erc721Temp);
    console.log(erc721Temp);
  }

  function getURLExplorer(address) {
    switch (web3ReactAlwaysOn.chainId) {
      case 1:
        return `https://etherscan.io/address/${address}`;
      case 4:
        return `https://rinkeby.etherscan.io/address/${address}`;
      case 5:
        return `https://goerli.etherscan.io/address/${address}`;
      case 80001:
        return `https://mumbai.polygonscan.com/address/${address}`;
      default:
        return "";
    }
  }
  getTokenURI();
  //Meebits and CORS
  //IPFS
  //

  return (
    <Grid container spacing={0} style={{ margin: "auto" }}>
      {/* {erc721?.metadata?.image || erc721?.metadata?.media?.uri ? ( */}
      <Grid item xs={12}>
        {erc721?.metadata?.media?.uri ? (
          <video
            autoPlay={true}
            preload={"metadata"}
            className={navImageClasses}
            controlsList="nodownload"
            loop={true}
            muted
            playsInline={true}
            poster={erc721.metadata.image}
            src={erc721.metadata.media.uri}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: "6px",
            }}
          ></video>
        ) : erc721?.metadata?.image ? (
          <div
            style={{
              minHeight: "400px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading && (
              <div>
                <CircularProgress />
              </div>
            )}

            <img
              src={erc721?.metadata?.image}
              style={{
                maxHeight: "400px",
                borderRadius: "6px",
                display: loading ? "none" : "block",
                maxWidth: "100%",
                width: "auto",
                height: "auto",
                margin: "auto",
              }}
              onLoad={() => setLoading(false)}
            />
          </div>
        ) : (
          <div
            style={{
              minHeight: "400px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/nft.png"}
              style={{
                minHeight: "400px",
                borderRadius: "6px",
                maxWidth: "100%",
                width: "auto",
                height: "auto",
                margin: "auto",
              }}
            />
          </div>
        )}
      </Grid>
      {/* ) : (
        ""
      )} */}

      {erc721?.metadata?.name ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <strong>{erc721?.name && erc721.metadata?.name}</strong>
        </Grid>
      ) : (
        ""
      )}

      {erc721?.metadata?.description ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <em>{erc721?.metadata?.description}</em>
        </Grid>
      ) : (
        ""
      )}

      <Grid item xs={12} sm={8}>
        Contract :{" "}
        <Tooltip disableFocusListener disableTouchListener title={address}>
          <Link
            to={{ pathname: getURLExplorer(address) }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatAddress(address)}
          </Link>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
        <Tooltip disableFocusListener disableTouchListener title={id}>
          <span># {formatId(id)}</span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

DisplayErc721.propTypes = {
  address: PropTypes.string,
  id: PropTypes.string,
};
