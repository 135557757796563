import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import Tooltip from "@material-ui/core/Tooltip";
import genericErc20Abi from "../../assets/lib/erc20Abi";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import { Snackbar } from "@material-ui/core";
import { useSnackbar } from "notistack";

// import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import treasuresTypesInfo from "assets/treasurestypes.json";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

//Ethereum connection
import { useWeb3React } from "@web3-react/core";
import { ethers, BigNumber } from "ethers";

import CryptoTreasures from "../../assets/lib/class.js";

function formatAddress(addr) {
  return addr.substr(0, 6) + "..." + addr.substr(-4);
}

const useStyles = makeStyles(styles);
export default function TreasureListFilteredPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const treasureTypeId = rest.match.params.id;
  const chainId = rest.match.params.chainId;

  const [treasuresArray, setTreasuresArray] = useState([]);
  const [reservedCount, setReservedCount] = useState(0);
  const [treasureType, setTreasureType] = useState({});
  const [cryptoTreasures, setCryptoTreasures] = useState(null);
  const [cryptoTreasuresAlwaysOn, setCryptoTreasuresAlwaysOn] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const web3React = useWeb3React();
  const web3ReactAlwaysOn = useWeb3React("alwaysOn");
  const { enqueueSnackbar } = useSnackbar();

  var nowInSec = Math.floor(Date.now() / 1000);

  useEffect(() => {
    if (web3React.active) {
      setCryptoTreasures(
        new CryptoTreasures(web3React.library.getSigner(), web3React.chainId)
      );
    } else {
      setCryptoTreasures(null);
    }
  }, [web3React]);

  useEffect(() => {
    if (web3ReactAlwaysOn.active) {
      setCryptoTreasuresAlwaysOn(
        new CryptoTreasures(
          web3ReactAlwaysOn.library,
          web3ReactAlwaysOn.chainId
        )
      );
    }
  }, [web3ReactAlwaysOn]);

  useEffect(() => {
    retrieveTreasuresFromThisType();
    const interval = setInterval(() => {
      console.log("Refresh treasures every 10s");
      retrieveTreasuresFromThisType();
    }, 10000);

    return () => clearInterval(interval);
  }, [cryptoTreasuresAlwaysOn]);

  function checkIfCorrectChain() {
    if (!cryptoTreasures || !cryptoTreasuresAlwaysOn) {
      displayMessage("Connect your wallet", "warning");
      return false;
    }

    if (
      cryptoTreasures.signer.provider._network.chainId !=
      web3ReactAlwaysOn.chainId
    ) {
      cryptoTreasures.signer.provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${web3ReactAlwaysOn.chainId.toString(16)}` }],
      });
      displayMessage(
        "Connect your wallet on the correct network in metamask",
        "warning"
      );
      return false;
    }
    return true;
  }

  //get all treasures from this type
  async function retrieveTreasuresFromThisType() {
    console.log(cryptoTreasuresAlwaysOn);
    if (!cryptoTreasuresAlwaysOn) return;
    try {
      var treasureTypes = await cryptoTreasuresAlwaysOn.getAllTypes();
    } catch (e) {
      console.log(JSON.stringify(e));
      displayMessage(
        "Treasure types not found on this chain, it might be a problem with The Graph Indexing",
        "warning"
      );
      rest.history.push("/");
      return null;
    }
    var treasureTypeFound;
    treasureTypes.filter((obj) => {
      if (obj.id === treasureTypeId) treasureTypeFound = obj;
    });

    treasureTypeFound.img =
      web3ReactAlwaysOn.chainId + "-" + treasureTypeFound.id;
    treasureTypeFound.numbers =
      treasureTypeFound.to - treasureTypeFound.from + 1;
    treasureTypeFound.name =
      treasuresTypesInfo[web3ReactAlwaysOn.chainId][treasureTypeFound.id].name;

    if (
      treasuresTypesInfo[web3ReactAlwaysOn.chainId][treasureTypeFound.id]
        .tokenToLockName
    )
      treasureTypeFound.tokenToLockName =
        treasuresTypesInfo[web3ReactAlwaysOn.chainId][
          treasureTypeFound.id
        ].tokenToLockName;

    if (treasureTypeFound.name != treasureType.name)
      setTreasureType(treasureTypeFound);

    var treasuresFiltered = await cryptoTreasuresAlwaysOn.getTreasuresByType(
      treasureTypeFound.id
    );
    // Count the number of treasures reserved minted
    var treasuresReservedCount = treasuresFiltered.reduce((acc, item) => {
      const id = BigNumber.from(item.id);
      const to = BigNumber.from(treasureTypeFound.to);
      if (id.gt(to.sub(treasureTypeFound.numberReserved))) {
        acc++;
      }
      return acc;
    }, 0);

    console.log("List of Treasures filtered");
    console.log(treasuresFiltered);
    console.log("treasuresReservedCount");
    console.log(treasuresReservedCount);
    if (treasuresArray.length != treasuresFiltered.length) {
      setTreasuresArray(treasuresFiltered);
      setReservedCount(treasuresReservedCount);
    }
  }

  async function mintOne() {
    if (!checkIfCorrectChain()) return;

    var type = await cryptoTreasures.getType(treasureTypeId);
    console.log(type);

    try {
      if (type.tokenToLock != "0x0000000000000000000000000000000000000000") {
        const erc20contract = new ethers.Contract(
          type.tokenToLock,
          genericErc20Abi.default,
          web3React.library.getSigner()
        );

        const allowance = await erc20contract.allowance(
          web3React.account,
          cryptoTreasures.address
        );

        if (BigNumber.from(type.amountToLock).gt(allowance)) {
          displayMessage("info: first, an approval is required", "info");
          const tx = await cryptoTreasures.approveERC20ToStore(
            type.tokenToLock
          );

          setConfirmationModal(true);
          await tx.wait(1);
          setConfirmationModal(false);
        }
      }

      await cryptoTreasures.mintByType(treasureTypeId, web3React.account);
      displayMessage(
        "Success: your transaction as been sent. Your chest will appear here once the transaction is validated. Then search for it using your owner address",
        "success"
      );
    } catch (e) {
      let error = cryptoTreasures.errorToHuman(e);
      if (JSON.stringify(e).indexOf("transfer amount exceeds balance") !== -1)
        return displayMessage(
          "It seems you do not have " +
            treasureType.amountToLock +
            " " +
            treasureType.tokenToLockName +
            " to lock. Ask on discord how to get some",
          "error"
        );
      displayMessage(error.message, "error");
    }
  }

  const imageClasses = classNames(classes.imgFluid);

  function displayMessage(text, type) {
    console.log(text);
    enqueueSnackbar(JSON.stringify(text), {
      variant: type,
      resumeHideDuration: "7000",
    });
  }

  return (
    <div>
      <Header
        color="transparent"
        brand="CryptoTreasures"
        rightLinks={<HeaderLinks chainId={chainId} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/background.jpg").default}
      />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "500px" }}
      >
        <div>
          <div className={classes.container}>
            <h2 className={classes.title}>{treasureType.name}</h2>

            <br />
            <small className={classes.description}>
              {treasureType.numbers +
                reservedCount -
                parseInt(treasureType.numberReserved) -
                treasuresArray.length}{" "}
              of {treasureType.numbers} are still claimable{" "}
            </small>
            <br />
            <small className={classes.description}>
              {parseInt(treasureType.mintingStartingTime) < 2147483647 ? (
                parseInt(treasureType.mintingStartingTime) > nowInSec ? (
                  <em>
                    Mintable from the{" "}
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }).format(treasureType.mintingStartingTime)}
                  </em>
                ) : (
                  <em>Mintable now</em>
                )
              ) : (
                <em>Treasure not mintable</em>
              )}
            </small>

            {treasureType.amountToLock != 0 && (
              <p style={{ fontWeight: 600 }}>
                You need to lock {treasureType.amountToLock}{" "}
                {treasureType.tokenToLockName} to mint one (Get one on{" "}
                <a
                  href="https://discord.gg/cwDfxFu3rE"
                  className={classes.block}
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
                )
              </p>
            )}
            {/* {Date.now()} {JSON.stringify(treasureType)} */}
            <div>
              {
                treasureType.numbers > treasuresArray.length &&
                  Date.now() > treasureType.mintingStartingTime && (
                    <div>
                      <Button color="primary" onClick={mintOne}>
                        Mint one
                      </Button>
                    </div>
                  )
                // <Button disabled color="primary">
                //   Mint one
                // </Button>
              }
            </div>
            <GridContainer justify="center">
              <Dialog
                classes={{
                  root: classes.center,
                  paper: classes.modal,
                }}
                open={confirmationModal}
                keepMounted
                onClose={() => setConfirmationModal(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Wait for approval confirmation please..."}
                </DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {"(This won't be necessary for the live version)"}
                  </DialogContentText>
                </DialogContent>
              </Dialog>

              {treasuresArray.map((treasure, index) => (
                <GridItem xs={12} sm={12} md={3} key={index}>
                  <Card>
                    <CardBody>
                      <Link
                        to={
                          "/treasure/" +
                          web3ReactAlwaysOn.chainId +
                          "/" +
                          treasure.id
                        }
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/treasures/" +
                            treasureType.img +
                            ".jpg"
                          }
                          alt="..."
                          className={imageClasses}
                        />
                        <div className={classes.title}>
                          <h3>#{treasure.id}</h3>
                        </div>
                      </Link>
                      <div>
                        <p className={classes.note}>
                          Owned by{" "}
                          {web3React?.account &&
                          treasure?.owner.toLowerCase() ===
                            web3React.account.toLowerCase() ? (
                            <strong>YOU</strong>
                          ) : (
                            <Tooltip
                              disableFocusListener
                              disableTouchListener
                              title={treasure?.owner}
                            >
                              <span>{formatAddress(treasure?.owner)}</span>
                            </Tooltip>
                          )}{" "}
                        </p>
                      </div>
                      <CardFooter></CardFooter>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

// <GridItem xs={12} sm={12} md={6}>
//   <div className={classes.profile}>
//     <div className={classes.name}>
//       <h3 className={classes.title}>{treasure.name} Chest</h3>
//       <h6>Owned by 0x45e6ff0885ebf5d616e460d14855455d92d6cc04</h6>

//       <h6>Max size : {treasure.size} elements</h6>
//       <div className={classes.description}>
//         <p>{treasure.desc}</p>
//       </div>
//   </div>
// </GridItem>
