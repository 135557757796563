import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";

// import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);
export default function TermsPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const imageClasses = classNames(classes.imgFluid);
  // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand="CryptoTreasures"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/background.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <h2 className={classes.title}>TERMS & CONDITIONS</h2>
            <br />

            <br />
            <small className={classes.description}>
              CryptoTreasures is a collection of digital artworks (NFTs) running
              on the Ethereum network. This website is only an interface
              allowing participants to exchange digital collectibles. Users are
              entirely responsible for the safety and management of their own
              private Ethereum wallets and validating all transactions and
              contracts generated by this website before approval. Furthermore,
              as the smart contract runs on the Ethereum network, there is no
              ability to undo, reverse, or restore any transactions. This
              website and its connected services are provided “as is” and “as
              available” without warranty of any kind. By using this website you
              are accepting sole responsibility for any and all transactions
              involving CryptoTreasures digital collectibles.
            </small>
            <br />
            <small className={classes.description}>
              This website is still in beta and funds can be lost. The project
              can not be held responsible for any loss. Use at your own risks
            </small>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
