import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import { Button } from "@material-ui/core";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Link } from "react-router-dom";

// import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import treasuresTypesInfo from "assets/treasurestypes.json";

const { addTypeParamToBytes } = require("../../assets/lib/utils");

//Ethereum connection
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import CryptoTreasures from "../../assets/lib/class.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);
export default function MyTreasuresPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const chainId = rest.match.params.chainId;

  const [myTreasures, setMyTreasures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cryptoTreasures, setCryptoTreasures] = useState(null);
  const [cryptoTreasuresAlwaysOn, setCryptoTreasuresAlwaysOn] = useState(null);

  const imageClasses = classNames(classes.imgFluid);

  const web3React = useWeb3React();
  const web3ReactAlwaysOn = useWeb3React("alwaysOn");

  useEffect(() => {
    if (web3React.active) {
      setCryptoTreasures(
        new CryptoTreasures(web3React.library, web3React.chainId)
      );
    } else {
      setCryptoTreasures(null);
    }
  }, [web3React]);

  useEffect(() => {
    if (web3ReactAlwaysOn.active) {
      setCryptoTreasuresAlwaysOn(
        new CryptoTreasures(
          web3ReactAlwaysOn.library,
          web3ReactAlwaysOn.chainId
        )
      );
    } else {
      setCryptoTreasuresAlwaysOn(null);
    }
  }, [web3ReactAlwaysOn]);

  useEffect(() => {
    if (web3React.active && cryptoTreasuresAlwaysOn && cryptoTreasures) {
      retrieveMyTreasures();
    }
  }, [cryptoTreasures, cryptoTreasuresAlwaysOn]);

  async function retrieveMyTreasures() {
    console.log("mytreasures");
    try {
      setLoading(true);
      var myTreasuresTemp =
        await cryptoTreasuresAlwaysOn.getAllTreasuresOfAddress(
          web3React.account
        );

      setMyTreasures(myTreasuresTemp);
      setLoading(false);
      console.log("mytreasures");
      console.log(myTreasuresTemp);
    } catch (e) {
      console.log(JSON.stringify(e));
      // displayMessage("Treasure types not found on this chain", "warning");
      rest.history.push("/");
      return null;
    }
  }

  // var nowInSec = Math.floor(Date.now() / 1000);

  return (
    <div>
      <Header
        color="transparent"
        brand="CryptoTreasures"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/background.jpg").default}
      />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "500px" }}
      >
        <div>
          <div className={classes.container}>
            <h2 className={classes.title}>My Chests</h2>
            <GridContainer justify="center">
              {!web3React.account ? (
                <div>Connect with Metamask to display your chests</div>
              ) : myTreasures.length == 0 && !loading ? (
                <div>You have no chest yet</div>
              ) : (
                myTreasures?.map((treasure, index) => (
                  <GridItem xs={12} sm={12} md={3} key={index}>
                    <Card>
                      <CardBody>
                        <Link
                          to={
                            "/treasure/" + web3React.chainId + "/" + treasure.id
                          }
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/img/treasures/" +
                              treasuresTypesInfo[chainId][treasure.type.id]
                                .img +
                              ".jpg"
                            }
                            alt="..."
                            className={imageClasses}
                          />
                          <div className={classes.title}>
                            <h5>#{treasure.id}</h5>
                            <h3>
                              {
                                treasuresTypesInfo[chainId][treasure.type.id]
                                  .name
                              }
                            </h3>
                          </div>
                        </Link>

                        <CardFooter></CardFooter>
                      </CardBody>
                    </Card>
                  </GridItem>
                ))
              )}
            </GridContainer>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
