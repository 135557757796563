import {} from "assets/jss/material-kit-react.js";

const erc20listStyle = {
  collection: {
    margin: ".5rem 0 1rem 0",
    border: "1px solid #e0e0e0",
    borderRadius: "2px",
    overflow: "hidden",
    position: "relative",
    paddingLeft: 0,
    listStyleType: "none",
  },
  collectionItem: {
    backgroundColor: "#fff",
    lineHeight: "1.5rem",
    padding: "10px 20px",
    margin: 0,
    borderBottom: "1px solid #e0e0e0",
  },
};

export default erc20listStyle;
