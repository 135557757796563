import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import blockies from "ethereum-blockies";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "assets/jss/material-kit-react/components/erc20listStyle.js";

import { useWeb3React } from "@web3-react/core";
//test
import { ethers, BigNumber } from "ethers";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DisplayErc721 from "./DisplayErc721.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CustomInput from "components/CustomInput/CustomInput.js";

import Check from "@material-ui/icons/Check";

import Button from "components/CustomButtons/Button.js";
import { ContactSupportOutlined } from "@material-ui/icons";
const useStyles = makeStyles(styles);

function formatId(id) {
  return id.length > 10 ? id.substr(0, 4) + "..." + id.substr(-4) : id;
}

function formatAddress(addr) {
  return addr.substr(0, 5) + "..." + addr.substr(-3);
}

function getURLExplorer(chainId, address) {
  switch (chainId) {
    // case 1:
    //   return `https://etherscan.io/address/${address}`;
    // case 4:
    //   return `https://rinkeby.etherscan.io/address/${address}`;
    case 5:
      return `https://goerli.etherscan.io/address/${address}`;
    case 137:
      return `https://polygonscan.com/address/${address}`;
    // case 80001:
    //   return `https://mumbai.polygonscan.com/address/${address}`;
    default:
      return "";
  }
}

export default function WithdrawComponent({
  cryptoTreasures,
  erc20TokensInsideChest,
  setWithdrawModal,
  treasure,
  displayMessage,
  checkIfCorrectChain,
}) {
  const classes = useStyles();

  const listClasses = classNames(classes.collectionItem);

  const [checked, setChecked] = React.useState([]);
  const [erc20TokensInsideChestTemp, setErc20sInChest] = React.useState(
    erc20TokensInsideChest
  );
  const [erc20InputArray, setErc20InputArray] = React.useState([]);
  const [assetsToWithdraw, setAssetsToWithdraw] = React.useState(null);

  const web3ReactAlwaysOn = useWeb3React("alwaysOn");
  const web3React = useWeb3React();

  //Retrieve NFTs and ERC20 of user.

  async function confirmWithdraw() {
    var assets = { erc20s: [], erc721s: [] };

    try {
      erc20InputArray.map((erc20) => {
        //Check if balance is enough
        let amountAvailable = treasure.erc20Balances.find((element) => {
          return element.address === erc20.address;
        }).amount;

        if (BigNumber.from(erc20.amount).gt(amountAvailable))
          throw "Not enough erc20 in treasure";

        assets.erc20s.push(erc20);
      });
    } catch (e) {
      return displayMessage(e, "danger");
    }

    checked.map((index) => {
      var resultIndex = assets.erc721s.findIndex((obj) => {
        return obj.address === treasure.erc721Balances[index[0]].address;
      });

      if (resultIndex > -1) {
        assets.erc721s[resultIndex].ids.push(
          treasure.erc721Balances[index[0]].ids[index[1]]
        );
      } else {
        assets.erc721s.push({
          address: treasure.erc721Balances[index[0]].address,
          ids: [treasure.erc721Balances[index[0]].ids[index[1]]],
        });
      }
      console.log("assets");
      console.log(JSON.stringify(assets));
    });
    setAssetsToWithdraw(assets);
  }

  async function withdrawAll() {
    try {
      if (!checkIfCorrectChain()) return;

      var result = await cryptoTreasures.withdraw(
        treasure.id,
        0,
        assetsToWithdraw.erc20s,
        assetsToWithdraw.erc721s,
        web3React.account //TODO Retirer ailleurs.
      );
    } catch (error) {
      console.log(error);
      return displayMessage(error.message, "danger");
    }
    displayMessage(
      "Withdrawal sent, refresh the page when the transaction is validated",
      "success"
    );
    setWithdrawModal(false);
  }

  Array.prototype.indexOfForArrays = function (search) {
    var searchJson = JSON.stringify(search);
    var arrJson = this.map(JSON.stringify);

    return arrJson.indexOf(searchJson);
  };

  const handleToggle = (index, index2) => {
    const currentIndex = checked.indexOfForArrays([index, index2]);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push([index, index2]);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  // function handleErc20InputChange(e, index) {
  //   let target = e.target;
  //   let address = target.id.split("-")[0];
  //   let decimals = target.id.split("-")[1];
  //   let amount = (target.value * 10 ** decimals).toString();
  //   // let amount2 = BigNumber(amount);

  //   let erc20InputArrayTemp = [...erc20InputArray];
  //   let index = erc20InputArrayTemp.findIndex((element) => {
  //     return element.address === address;
  //   });

  //   index == -1
  //     ? erc20InputArrayTemp.push({ address: address, amount: amount })
  //     : (erc20InputArrayTemp[index].amount = amount);

  //   setErc20InputArray(erc20InputArrayTemp);
  // }

  function handleErc20InputChange(e, i) {
    let target = e.target;
    let address = target.id.split("-")[0];
    let decimals = target.id.split("-")[1];

    let erc20InputArrayTemp = [...erc20InputArray];

    let indexInsideChest = erc20TokensInsideChestTemp.findIndex((element) => {
      return element.address === address;
    });
    erc20TokensInsideChestTemp[indexInsideChest] = {
      ...erc20TokensInsideChestTemp[indexInsideChest],
      error: false,
      errorMessage: "",
    };
    console.log("erc20TokensInsideChestTemp[indexInsideChest]");
    console.log(erc20TokensInsideChestTemp[indexInsideChest]);

    let index = erc20InputArrayTemp.findIndex((element) => {
      return element.address === address;
    });
    if (index != -1) erc20InputArrayTemp.splice(index, 1);

    if (target.value != "") {
      try {
        let amount = ethers.utils.parseUnits(target.value, decimals);
        if (amount.gt(erc20TokensInsideChestTemp[indexInsideChest].amount)) {
          erc20TokensInsideChestTemp[indexInsideChest] = {
            ...erc20TokensInsideChestTemp[indexInsideChest],
            error: true,
            errorMessage: "Not enough balance in chest",
          };
        } else {
          if (amount != 0) {
            erc20InputArrayTemp.push({
              address: address,
              decimals: decimals,
              symbol: erc20TokensInsideChestTemp[indexInsideChest].symbol,
              amount: amount.toString(),
            });
          }
        }
      } catch (e) {
        console.log(e.message);
        erc20TokensInsideChestTemp[indexInsideChest] = {
          ...erc20TokensInsideChestTemp[indexInsideChest],

          error: true,
          errorMessage: "Must be a number",
        };
      }
    }
    setErc20sInChest(erc20TokensInsideChestTemp);
    setErc20InputArray(erc20InputArrayTemp);
  }

  function createBlockies(seed) {
    return blockies.create({
      // All options are optional
      seed, // seed used to generate icon data, default: random
      color: "#dfe", // to manually specify the icon color, default: random
      bgcolor: "#aaa", // choose a different background color, default: random
      // size: 10, // width/height of the icon in blocks, default: 8
      // scale: 2, // width/height of each block in pixels, default: 4
      spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
    });
  }

  function reset() {
    setAssetsToWithdraw(null);
    setErc20InputArray([]);
    setChecked([]);
  }

  return (
    <div>
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setWithdrawModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>

        <h4 className={classes.modalTitle}>
          Withdraw {assetsToWithdraw ? " confirmation" : ""}
        </h4>
      </DialogTitle>

      {assetsToWithdraw ? (
        <span>
          {" "}
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <div>
              {assetsToWithdraw.erc20s.length == 0 ? null : (
                <div>
                  <h5 className={classes.modalTitle}>ERC20 to withdraw:</h5>
                  <ul>
                    {assetsToWithdraw.erc20s.map((erc20, index) => (
                      <li key={index}>
                        {ethers.utils.formatUnits(erc20.amount, erc20.decimals)}{" "}
                        {erc20.symbol}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {assetsToWithdraw.erc721s.length == 0 ? null : (
                <div>
                  <h5 className={classes.modalTitle}>ERC721 to withdraw:</h5>
                  <ul>
                    {assetsToWithdraw.erc721s.map((erc721, index) => (
                      <li key={index}>
                        <Link
                          to={{
                            pathname: getURLExplorer(
                              web3React.chainId,
                              erc721.address
                            ),
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(erc721.address)}
                        </Link>
                        <ul>
                          {erc721.ids.map((id, index2) => (
                            <li key={index2}>
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={id}
                              >
                                <span># {formatId(id)}</span>
                              </Tooltip>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div>
              <h5 className={classes.modalTitle}>
                Withdraw of all the tokens selected in a single transaction.
              </h5>
              {/* <small>Validate all allowance before unlocking Deposit</small> */}
              <Button onClick={(e) => withdrawAll()} color="success">
                Withdraw
              </Button>
            </div>
            {/* ))} */}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => reset()} color="danger" simple>
              Previous
            </Button>
          </DialogActions>
        </span>
      ) : (
        <span>
          {" "}
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <h4 className={classes.modalTitle}>ERC20 in the chest</h4>{" "}
            {erc20TokensInsideChestTemp
              .filter((e) => e.amount != "0")
              .map((erc20, index) => (
                <Card key={index}>
                  <CardBody>
                    <img
                      src={
                        erc20.logoURI ||
                        createBlockies(erc20.address).toDataURL()
                      }
                      alt=""
                      className="circle"
                      style={{
                        height: "30px",
                        margin: "10px",
                        borderRadius: "50%",
                      }}
                    />
                    <span className="">
                      {erc20.name} :{" "}
                      {ethers.utils.formatUnits(erc20.amount, erc20.decimals)}{" "}
                      {erc20.symbol} available
                      {
                        <CustomInput
                          id={erc20.address + "-" + erc20.decimals}
                          inputProps={{
                            placeholder: "How much would you like to withdraw?",
                            onChange: (e) => handleErc20InputChange(e, index),
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          labelText={erc20.errorMessage}
                          error={erc20.error}
                        />
                      }
                    </span>
                    {/* </div> */}
                  </CardBody>
                </Card>
              ))}
            {!erc20TokensInsideChestTemp.filter((e) => e.amount != "0")
              .length && <p>No ERC20 found in this chest</p>}
            <h4 className={classes.modalTitle}>NFTs in your chest</h4>
            {treasure?.erc721Balances?.map((erc721PerContract, index) => (
              <div key={index}>
                {erc721PerContract.ids.map((id, index2) => (
                  <Card key={index2}>
                    <CardBody
                      style={{
                        backgroundColor:
                          checked.indexOfForArrays([index, index2]) !== -1
                            ? "#eee"
                            : "white",
                      }}
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={1} style={{ margin: "auto" }}>
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleToggle(index, index2)}
                          />
                        </Grid>
                        <Grid item xs={11} style={{ margin: "auto" }}>
                          <DisplayErc721
                            address={erc721PerContract.address}
                            id={id.toString()}
                          ></DisplayErc721>
                        </Grid>
                      </Grid>
                    </CardBody>
                  </Card>
                ))}
              </div>
            ))}
            {!treasure?.erc721Balances.length && (
              <p>No NFTs found in this chest</p>
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              color="success"
              onClick={confirmWithdraw}
              disabled={erc20InputArray.length == 0 && checked.length == 0}
            >
              Withdraw
            </Button>
            <Button
              onClick={() => setWithdrawModal(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </span>
      )}
    </div>
  );
}

WithdrawComponent.propTypes = {
  treasure: PropTypes.object,
  setWithdrawModal: PropTypes.func,
  cryptoTreasures: PropTypes.object,
  erc20TokensInsideChest: PropTypes.array,
  displayMessage: PropTypes.func,

  checkIfCorrectChain: PropTypes.func,
};
