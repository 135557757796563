import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/Style1.js";

const useStyles = makeStyles(styles);

export default function Section1() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>
            NFT infrastructure for the metaverse
          </h2>
          <h4 className={classes.description}>
            NFTs that can store other NFTs and ERC20
          </h4>
          <h4 className={classes.description}>Soon on Polygon and Ethereum</h4>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <h4 className={classes.title}>Giveaways and gifts</h4>
            <p className={classes.description}>
              Store and give a basket of tokens
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <h4 className={classes.title}>Easy NFTs transportation</h4>
            <p className={classes.description}>
              Transfer the ownership of your chest and the whole content travels
              with it. Deposit and withdraw many NFTs/ERC20 in a single
              transaction.
            </p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <h4 className={classes.title}>Something else entirely</h4>
            <p className={classes.description}>
              NFT galleries, ingame item boxes, selling entire NFTs collections
              as one object or whatever you can imagine.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
