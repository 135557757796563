import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { SnackbarProvider } from "notistack";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import TermsPage from "views/Others/Terms.js";
import TreasurePage from "views/TreasurePage/TreasurePage.js";
import MyTreasures from "views/TreasurePage/MyTreasuresPage.js";
import TreasureMetadata from "views/TreasurePage/TreasureMetadata.js";
import TreasureListPage from "views/TreasureListPage/TreasureListPage.js";
import TreasureListFilteredPage from "views/TreasureListFilteredPage/TreasureListFilteredPage.js";

//Ethereum connection
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { Web3Provider, StaticJsonRpcProvider } from "@ethersproject/providers";

function getLibrary(provider) {
  //, connector
  return new Web3Provider(provider);
}

function getAlwaysOnLibrary(provider) {
  //, connector
  return new StaticJsonRpcProvider(provider);
}

//Another connection if the user is not connected to pull the data.
const Web3ReactProviderReloaded = createWeb3ReactRoot("alwaysOn");

var hist = createBrowserHistory();

ReactDOM.render(
  <CookiesProvider>
    <SnackbarProvider maxSnack={3}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ReactProviderReloaded getLibrary={getAlwaysOnLibrary}>
          <Router history={hist}>
            <Switch>
              {/* <Route path="/components" component={Components} /> */}
              <Route
                path="/treasure-list/:chainId"
                component={TreasureListPage}
              />
              <Route
                path="/treasure-list-filtered/:chainId/:id"
                component={TreasureListFilteredPage}
              />
              <Route path="/treasure/:chainId/:id" component={TreasurePage} />
              {/* <Route
                path="/treasure-metadata/:chainId/:id"
                component={TreasureMetadata}
              /> */}
              <Route path="/my-treasures/:chainId" component={MyTreasures} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/" component={LandingPage} />
            </Switch>
          </Router>
        </Web3ReactProviderReloaded>
      </Web3ReactProvider>
    </SnackbarProvider>
  </CookiesProvider>,
  document.getElementById("root")
);
