const errorsList = {
    "e0": "slicing out of range",

    "e1": 'token already in the chest',
    "e2": "only transfer from this very contract is accepted",
    "e3": "box is destroyed",
    "e4": "only approver or owner can withdraw or destroy",
    "e5": "only approver or owner can destroy a box",
    "e6": 'token not found in the box',

    "e7": "store restricted to the owner for this box",

    "e8": "box time locked",

    "e9": "Type id not found",
    "e10": "box type not found",
    "e11": "boxId too low",
    "e12": "boxId too high",
    "e13": "type already defined",
    "e14": "from must be stricly smaller than to",

    "e15": "box must exist",
    "e16": "source box does not contains the erc721 token",

    "e17": "box destruction is locked",
    "e18": "no token available for this type",

    "e19": "type not mintable yet",

    "e20": "storing a box in a box is forbidden",

    "e21": "cannot reserve more than tokens existing",
    "e22": "token reserved",

    "e23": "delegateCall failed",
};

module.exports = {
    toHuman: (e) => {
        return errorsList[e];
    },
    errorsList
}
