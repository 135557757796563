import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import { Button } from "@material-ui/core";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { Link } from "react-router-dom";

// import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import treasuresTypesInfo from "assets/treasurestypes.json";

const { addTypeParamToBytes } = require("../../assets/lib/utils");

//Ethereum connection
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import CryptoTreasures from "../../assets/lib/class.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);
export default function TreasureListPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const chainId = rest.match.params.chainId;

  const [treasureTypesArray, setTreasureTypesArray] = useState([]);
  const [cryptoTreasuresAlwaysOn, setCryptoTreasuresAlwaysOn] = useState(null);

  const imageClasses = classNames(classes.imgFluid);

  const web3ReactAlwaysOn = useWeb3React("alwaysOn");

  useEffect(() => {
    if (web3ReactAlwaysOn.active) {
      setCryptoTreasuresAlwaysOn(
        new CryptoTreasures(
          web3ReactAlwaysOn.library,
          web3ReactAlwaysOn.chainId
        )
      );
    } else {
      setCryptoTreasuresAlwaysOn(null);
    }
  }, [web3ReactAlwaysOn]);

  useEffect(() => {
    if (web3ReactAlwaysOn.active && cryptoTreasuresAlwaysOn) {
      retrieveTreasureTypes();
    }
  }, [cryptoTreasuresAlwaysOn]);

  async function retrieveTreasureTypes() {
    try {
      var treasureTypes = await cryptoTreasuresAlwaysOn.getAllTypes();

      treasureTypes = treasureTypes.map((itemType) => ({
        ...itemType,
        img: web3ReactAlwaysOn.chainId + "-" + itemType.id,
        numbers: itemType.to - itemType.from + 1,
        numbersMinted: itemType.treasures.length,
        reservedMintedCount: itemType.treasures.reduce((acc, item) => {
          const id = BigNumber.from(item.id);
          const to = BigNumber.from(itemType.to);
          if (id.gt(to.sub(itemType.numberReserved))) {
            acc++;
          }
          return acc;
        }, 0),
        name: treasuresTypesInfo[web3ReactAlwaysOn.chainId][itemType.id]?.name,
      }));
      console.log("List of Treasure types");
      console.log(treasureTypes);
      if (treasureTypes.length != treasureTypesArray.length)
        setTreasureTypesArray(treasureTypes);
    } catch (e) {
      console.log(JSON.stringify(e));
      // displayMessage("Treasure types not found on this chain", "warning");
      rest.history.push("/");
      return null;
    }
  }

  var nowInSec = Math.floor(Date.now() / 1000);

  return (
    <div>
      <Header
        color="transparent"
        brand="CryptoTreasures"
        rightLinks={<HeaderLinks chainId={chainId} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/background.jpg").default}
      />
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "500px" }}
      >
        <div>
          <div className={classes.container}>
            <h2 className={classes.title}>Chests</h2>
            <GridContainer justify="center">
              {treasureTypesArray.map((treasureType, index) => (
                <GridItem xs={12} sm={12} md={4} key={index}>
                  <Card>
                    <CardBody>
                      <Link
                        to={
                          "/treasure-list-filtered/" +
                          web3ReactAlwaysOn.chainId +
                          "/" +
                          treasureType.id
                        }
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/treasures/" +
                            treasureType.img +
                            ".jpg"
                          }
                          alt="..."
                          className={imageClasses}
                        />
                        <div className={classes.title}>
                          <h3>{treasureType.name}</h3>
                        </div>
                      </Link>
                      <div>
                        <p className={classes.note}>
                          {treasureType.numbers +
                            treasureType.reservedMintedCount -
                            parseInt(treasureType.numberReserved) -
                            treasureType.numbersMinted}{" "}
                          of {treasureType.numbers} are still claimable
                        </p>

                        <p className={classes.note}>
                          {parseInt(treasureType.mintingStartingTime) <
                          2147483647 ? (
                            parseInt(treasureType.mintingStartingTime) >
                            nowInSec ? (
                              <em>
                                Mintable from the{" "}
                                {new Intl.DateTimeFormat("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }).format(treasureType.mintingStartingTime)}
                              </em>
                            ) : (
                              <em>Mintable now</em>
                            )
                          ) : (
                            <em>Treasure not mintable</em>
                          )}
                        </p>
                      </div>

                      <CardFooter></CardFooter>
                    </CardBody>
                  </Card>
                </GridItem>
              ))}
            </GridContainer>
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

// <GridItem xs={12} sm={12} md={6}>
//   <div className={classes.profile}>
//     <div className={classes.name}>
//       <h3 className={classes.title}>{treasure.name} Chest</h3>
//       <h6>Owned by 0x45e6ff0885ebf5d616e460d14855455d92d6cc04</h6>

//       <h6>Max size : {treasure.size} elements</h6>
//       <div className={classes.description}>
//         <p>{treasure.desc}</p>
//       </div>
//   </div>
// </GridItem>
