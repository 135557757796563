const { ethers, BigNumber } = require("ethers");
const {
  addTypeParamToBytes,
  mintParamToBytes,
  mintByTypeParamToBytes,
  parseDataTypes,
} = require("./utils");
const ERC20Abi = require("./erc20Abi.js").default;
const ERC721Abi = require("./erc721Abi.js").default;
const CTAbi = require("./cryptoTreasureAbi.js").default;
const addressValidator = require("multicoin-address-validator");
const errorCodeManager = require("./solidity-errors");
const { request } = require("graphql-request");

const BASE_URL = `https://api.thegraph.com`;

const query = async (query, network) => {
  const url = `${BASE_URL}/subgraphs/name/vrolland/cryptotreasure-${network}`;
  return request(url, query);
};

const asyncFilter = async (arr, predicate) => {
  const results = await Promise.all(arr.map(predicate));

  return arr.filter((_v, index) => results[index]);
};

function nowInSecond() {
  return Math.floor(Date.now() / 1000);
}

class CryptoTreasures {
  constructor(_signer, _network = "1337") {
    const networkId = _network.toString();
    if (networkId === "1337") {
      this.address = "0x8f0483125FCb9aaAEFA9209D8E9d7b9C8B9Fb90F";
      this.network = "private";
      throw Error(`network not supported: ${networkId}`);
      // } else if (networkId === "80001") {
      //   // mumbai
      //   this.address = "0xcc35421a9050300d0b66419727f191ac92b85f69";
      //   this.network = "mumbai";
    } else if (networkId === "137") {
      // Polygon
      this.address = "0xe079d869156d41f5594ff3f41c28023cec73fb02";
      this.network = "polygon";
    } else if (networkId === "5") {
      // Goerli
      this.address = "0xf621EA3EA0D4716Bfe5AFCFDAaA033F902914B0C";
      this.network = "goerli";
      // } else if (networkId === "4") {
      //   // rinkeby
      //   this.address = "0xDfC666757FD571043EBDAcCA238C61Eaf2520f82";
      //   this.network = "rinkeby";
    } else {
      throw Error(`network id not supported: ${networkId}`);
    }

    this.signer = _signer;
    this.abi = CTAbi;
    this.contract = new ethers.Contract(this.address, this.abi, this.signer);
  }

  // Writer
  async addType(
    id,
    from,
    to,
    erc20ToLock,
    amountToLock,
    durationLockDestroy,
    mintingDurationLock,
    numberReserved
  ) {
    const data = addTypeParamToBytes(
      erc20ToLock,
      amountToLock,
      durationLockDestroy,
      mintingDurationLock,
      numberReserved
    );
    return await this.contract.addType(id, from, to, data);
  }

  async mint(treasureId, to, restriction = false) {
    if (!addressValidator.validate(to, "ETH")) {
      throw Error(`invalid address: ${to}`);
    }

    const id = BigNumber.from(treasureId);
    const allTypes = await this.getAllTypes();
    const typesFiltered = allTypes.filter(
      (t) => BigNumber.from(t.from).lte(id) && BigNumber.from(t.to).gte(id)
    );

    if (typesFiltered.length < 1) {
      throw "No type found for this treasure id";
    }
    const typeId = typesFiltered[0].id;

    return await this.contract.safeMint(
      to,
      treasureId,
      mintParamToBytes(typeId, restriction)
    );
  }

  async mintByType(typeId, to, restriction = false) {
    if (!addressValidator.validate(to, "ETH")) {
      throw Error(`invalid address: ${to}`);
    }

    return await this.contract.safeMintByType(
      to,
      typeId,
      mintByTypeParamToBytes(restriction)
    );
  }

  async mintBatchByType(typeId, listTo, restriction = false) {
    const invalidAddresses = listTo.filter(
      (to) => !addressValidator.validate(to, "ETH")
    );

    if (invalidAddresses.length === 0) {
      throw Error(`invalid addresses found: ${invalidAddresses.toString()}`);
    }

    return await this.contract.safeBatchMintByType(
      listTo,
      typeId,
      mintByTypeParamToBytes(restriction)
    );
  }

  async transfer(treasureId, to) {
    if (!addressValidator.validate(to, "ETH")) {
      throw Error(`invalid address: ${to}`);
    }
    const from = await this.signer.getAddress();
    return await this.contract.transferFrom(from, to, treasureId);
  }

  async approve(treasureId, spender) {
    if (!addressValidator.validate(spender, "ETH")) {
      throw Error(`invalid address: ${spender}`);
    }
    const treasureIdBN = BigNumber.from(treasureId);
    return await this.contract.approve(spender, treasureIdBN.toString());
  }

  async approveAll(operator) {
    if (!addressValidator.validate(operator, "ETH")) {
      throw Error(`invalid address: ${operator}`);
    }
    return await this.contract.setApprovalForAll(operator, true);
  }

  async store(treasureId, ethAmount, erc20s, erc721s) {
    const erc20params = [];
    const erc721params = [];

    let balance = await this.signer.getBalance();
    const ETHAmountBN = BigNumber.from(ethAmount);
    if (ETHAmountBN.gt(balance)) {
      throw "not enough ethers balance";
    }

    for (const erc20 of erc20s) {
      const erc20contract = new ethers.Contract(
        erc20.address,
        ERC20Abi,
        this.signer
      );
      const amountBN = BigNumber.from(erc20.amount);

      balance = await erc20contract.balanceOf(await this.signer.getAddress());
      if (amountBN.gt(balance)) {
        throw "not enough balance";
      }

      const allowance = await erc20contract.allowance(
        await this.signer.getAddress(),
        this.address
      );
      if (amountBN.gt(allowance)) {
        throw "not enough allowance";
      }
      erc20params.push([erc20.address, amountBN.toString()]);
    }

    for (const erc721 of erc721s) {
      const erc721contract = new ethers.Contract(
        erc721.address,
        ERC721Abi,
        this.signer
      );

      const tokenIdsStr = erc721.ids.map((id) => BigNumber.from(id).toString());

      const isApprovedForAll = await erc721contract.isApprovedForAll(
        await this.signer.getAddress(),
        this.address
      );
      if (!isApprovedForAll) {
        for (const id of tokenIdsStr) {
          const approvedAddress = await erc721contract.getApproved(id);
          if (approvedAddress.toLowerCase() !== this.address.toLowerCase()) {
            throw "no allowance for the tokens";
          }
        }
      }
      erc721params.push([erc721.address, tokenIdsStr]);
    }

    return await this.contract.store(
      treasureId,
      erc20params,
      erc721params,
      [],
      { value: ETHAmountBN }
    );
  }

  async withdraw(treasureId, ethAmount, erc20s, erc721s, to) {
    if (!addressValidator.validate(to, "ETH")) {
      throw Error(`invalid address: ${to}`);
    }
    const erc20params = [];
    const erc721params = [];

    let balance = await this.contract.EthBalanceOf(treasureId);
    const ETHAmountBN = BigNumber.from(ethAmount);
    if (ETHAmountBN.gt(balance)) {
      throw "not enough ethers balance in the treasure";
    }

    for (const erc20 of erc20s) {
      const amountBN = BigNumber.from(erc20.amount);

      balance = await this.contract.erc20BalanceOf(treasureId, erc20.address);
      if (amountBN.gt(balance)) {
        throw "not enough balance in the treasure";
      }
      erc20params.push([erc20.address, amountBN.toString()]);
    }

    for (const erc721 of erc721s) {
      const tokenIdsStr = erc721.ids.map((id) => BigNumber.from(id).toString());
      for (const id of tokenIdsStr) {
        const inTreasure = await this.contract.erc721BalanceOf(
          treasureId,
          erc721.address,
          id
        );
        if (!inTreasure) {
          throw "token not in the treasure";
        }
      }
      erc721params.push([erc721.address, tokenIdsStr]);
    }

    return await this.contract.withdraw(
      treasureId,
      ETHAmountBN,
      erc20params,
      erc721params,
      [],
      to
    );
  }

  async destroy(treasureId, ethAmount, erc20s, erc721s, to) {
    if (!addressValidator.validate(to, "ETH")) {
      throw Error(`invalid address: ${to}`);
    }

    const erc20params = [];
    const erc721params = [];

    let balance = await this.contract.EthBalanceOf(treasureId);
    const ETHAmountBN = BigNumber.from(ethAmount);
    if (ETHAmountBN.gt(balance)) {
      throw "not enough ethers balance in the treasure";
    }

    for (const erc20 of erc20s) {
      const amountBN = BigNumber.from(erc20.amount);

      balance = await this.contract.erc20BalanceOf(treasureId, erc20.address);
      if (amountBN.gt(balance)) {
        throw "not enough balance in the treasure";
      }
      erc20params.push([erc20.address, amountBN.toString()]);
    }

    for (const erc721 of erc721s) {
      const tokenIdsStr = erc721.ids.map((id) => BigNumber.from(id).toString());
      for (const id of tokenIdsStr) {
        const inTreasure = await this.contract.erc721BalanceOf(
          treasureId,
          erc721.address,
          id
        );
        if (!inTreasure) {
          throw "token not in the treasure";
        }
      }
      erc721params.push([erc721.address, tokenIdsStr]);
    }

    return await this.contract.destroy(
      treasureId,
      ETHAmountBN,
      erc20params,
      erc721params,
      [],
      to
    );
  }

  async transferBetweenBoxes(
    srcTreasureId,
    destTreasureId,
    ethAmount,
    erc20s,
    erc721s
  ) {
    const erc20params = [];
    const erc721params = [];

    let balance = await this.contract.EthBalanceOf(srcTreasureId);
    const ETHAmountBN = BigNumber.from(ethAmount);
    if (ETHAmountBN.gt(balance)) {
      throw "not enough ethers balance in the treasure";
    }

    for (const erc20 of erc20s) {
      const amountBN = BigNumber.from(erc20.amount);

      const balance = await this.contract.erc20BalanceOf(
        srcTreasureId,
        erc20.address
      );
      if (amountBN.gt(balance)) {
        throw "not enough balance in the treasure";
      }
      erc20params.push([erc20.address, amountBN.toString()]);
    }

    for (const erc721 of erc721s) {
      const tokenIdsStr = erc721.ids.map((id) => BigNumber.from(id).toString());
      for (const id of tokenIdsStr) {
        const inTreasure = await this.contract.erc721BalanceOf(
          srcTreasureId,
          erc721.address,
          id
        );
        if (!inTreasure) {
          throw "token not in the treasure";
        }
      }
      erc721params.push([erc721.address, tokenIdsStr]);
    }

    return await this.contract.transferBetweenBoxes(
      srcTreasureId,
      destTreasureId,
      ETHAmountBN,
      erc20params,
      erc721params,
      []
    );
  }

  async lockBox(treasureId, unlockTimestampINSECONND) {
    return await this.contract.lockBox(treasureId, unlockTimestampINSECONND);
  }

  async setStoreRestrictionToOwnerAndApproval(treasureId, restriction) {
    return await this.contract.setStoreRestrictionToOwnerAndApproval(
      treasureId,
      restriction
    );
  }

  async approveERC20ToStore(
    erc20address,
    amount = BigNumber.from(2).pow(256).sub(1)
  ) {
    if (!addressValidator.validate(erc20address, "ETH")) {
      throw Error(`invalid address: ${erc20address}`);
    }
    const erc20contract = new ethers.Contract(
      erc20address,
      ERC20Abi,
      this.signer
    );
    const amountBN = BigNumber.from(amount);
    return await erc20contract.approve(this.address, amountBN.toString());
  }

  async approveERC721ToStore(erc721address, tokenId) {
    if (!addressValidator.validate(erc721address, "ETH")) {
      throw Error(`invalid address: ${erc721address}`);
    }
    const erc721contract = new ethers.Contract(
      erc721address,
      ERC721Abi,
      this.signer
    );
    const tokenIdBN = BigNumber.from(tokenId);
    return await erc721contract.approve(this.address, tokenIdBN.toString());
  }

  async approveAllERC721ToStore(erc721address) {
    if (!addressValidator.validate(erc721address, "ETH")) {
      throw Error(`invalid address: ${erc721address}`);
    }
    const erc721contract = new ethers.Contract(
      erc721address,
      ERC721Abi,
      this.signer
    );
    return await erc721contract.setApprovalForAll(this.address, true);
  }

  errorToHuman(e) {
    try {
      const codeError = e.error.stack.split("revert ")[1].split(`\n`)[0];
      return errorCodeManager.toHuman(codeError);
    } catch (internalError) {
      return e;
    }
  }

  // Reader
  async getAllTypes() {
    const result = await query(
      `{
        types {
          id
          from
          to
          tokenToLock
          amountToLock
          durationLockDestroy
          mintingStartingTime
          numberReserved
          treasures {
            id
          }
        }
      }`,
      this.network
    );

    return result.types;
  }

  async getType(typeId) {
    const result = await query(
      `{
        types ( first:1, where: {id : "${typeId}"} ) {
          id
          from
          to
          tokenToLock
          amountToLock
          durationLockDestroy
          mintingStartingTime
          numberReserved
          treasures {
            id
          }
        }
      }`,
      this.network
    );

    if (result.types.length == 0) {
      throw Error("type not found");
    }

    return result.types[0];
  }

  async getAllTreasures() {
    const result = await query(
      `{
        treasures {
          id
          owner
          destroyed
          type {
            id
          }
        }
      }`,
      this.network
    );

    return result.treasures;
  }

  async getTreasuresByType(typeId) {
    const result = await query(
      `{
        treasures ( where: {type : "${typeId}"} ) {
          id
          owner
          destroyed
          type {
            id
          }
        }
      }`,
      this.network
    );

    return result.treasures;
  }

  async getAllTreasuresOfAddress(address) {
    const result = await query(
      `{
        treasures ( where: {owner : "${address}"} ) {
          id
          owner
          destroyed
          type {
            id
          }
        }
      }`,
      this.network
    );

    return result.treasures;
  }

  async getTreasureDetails(treasureIdStr) {
    const result = await query(
      `{
        treasures ( first:1, where: {id : "${treasureIdStr}"} ) {
            id
            type {
              id
              from
              to
              tokenToLock
              amountToLock
              durationLockDestroy
              mintingStartingTime
              numberReserved
            }
            owner
            uri
            ethBalance
            erc20Balances {
                address
                amount
            }
            erc721Balances {
                address
                ids
            }
            erc1155Balances {
                address
                ids
                amounts
            }
            destroyed
            isStoreRestricted
            lockedUntil
            lockedDestructionEnd
        }
      }`,
      this.network
    );

    if (result.treasures.length == 0) {
      throw Error("treasure not found");
    }
    const treasure = result.treasures[0];

    treasure.isLocked = treasure.lockedUntil >= Math.floor(Date.now() / 1000);
    treasure.uri = await this.contract.tokenURI(treasureIdStr);
    return treasure;
  }
}

// module.exports = CryptoTreasures;
export default CryptoTreasures;
