import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/Style2.js";

const useStyles = makeStyles(styles);

export default function Section2() {
  const classes = useStyles();
  const imageClasses = classNames(classes.imgFluid);
  console.log("imageClasses");
  console.log(imageClasses);

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Free but Rare</h2>
      <div>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={6}>
            <img
              src={process.env.PUBLIC_URL + "/img/treasures/4-1.jpg"}
              alt="..."
              className={imageClasses}
              style={{ maxHeight: "400px" }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h5 className={classes.description}>Mint a classic one for free</h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <h5 className={classes.description}>
              ​Some rare CryptoTreasures can be obtained by locking ERC20s
              tokens. Destroy the chest to get your tokens back.
            </h5>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <img
              src={process.env.PUBLIC_URL + "/img/treasures/80001-2b.jpg"}
              alt="..."
              className={imageClasses}
              style={{ maxHeight: "400px" }}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
