import blockies from "ethereum-blockies";
import Grid from "@material-ui/core/Grid";

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { ethers } from "ethers";

import styles from "assets/jss/material-kit-react/components/erc20listStyle.js";

const useStyles = makeStyles(styles);

export default function Erc20List({ erc20List }) {
  console.log({ erc20List });
  const classes = useStyles();

  const listClasses = classNames(classes.collectionItem);

  function createBlockies(seed) {
    return blockies.create({
      // All options are optional
      seed, // seed used to generate icon data, default: random
      color: "#dfe", // to manually specify the icon color, default: random
      bgcolor: "#aaa", // choose a different background color, default: random
      // size: 10, // width/height of the icon in blocks, default: 8
      // scale: 2, // width/height of each block in pixels, default: 4
      spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
    });
  }

  return (
    <div>
      {erc20List.filter((e) => e.amount != "0").length > 0 && (
        <ul className={classes.collection}>
          {erc20List.map((erc20, index) =>
            erc20.amount == "0" ? null : (
              <li className={listClasses} key={index}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4} style={{ padding: "5px" }}>
                    <img
                      src={
                        erc20.logoURI ||
                        createBlockies(erc20.address).toDataURL()
                      }
                      alt=""
                      className="circle"
                      style={{
                        height: "30px",
                        margin: "10px",
                        borderRadius: "50%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} style={{ margin: "auto" }}>
                    {erc20.name}
                  </Grid>
                  <Grid item xs={12} style={{ padding: "5px" }}>
                    <strong>
                      {ethers.utils.formatUnits(erc20.amount, erc20.decimals)}{" "}
                      {erc20.symbol}
                    </strong>
                  </Grid>
                </Grid>
              </li>
            )
          )}
        </ul>
      )}
      {erc20List.filter((e) => e.amount != "0").length == 0 &&
        "There is no ERC20"}
    </div>
  );
}

Erc20List.defaultProps = {
  // color: "primary",
};

Erc20List.propTypes = {
  // pages: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     active: PropTypes.bool,
  //     disabled: PropTypes.bool,
  //     text: PropTypes.oneOfType([
  //       PropTypes.number,
  //       PropTypes.oneOf(["PREV", "NEXT", "..."]),
  //     ]).isRequired,
  //     onClick: PropTypes.func,
  //   })
  // ).isRequired,
  erc20List: PropTypes.array,
};
