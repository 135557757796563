import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Erc20List from "components/Erc20List/Erc20List.js";
import DepositComponent from "./DepositComponent.js";
import WithdrawComponent from "./WithdrawComponent.js";
import DisplayErc721 from "./DisplayErc721.js";
import Parallax from "components/Parallax/Parallax.js";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Datetime from "react-datetime";

import CustomInput from "components/CustomInput/CustomInput.js";

import treasuresTypesInfo from "assets/treasurestypes.json";
// import coingeckoTokenListJSON from "assets/coingeckoTokenList.json";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

//Ethereum connection
import { useWeb3React } from "@web3-react/core";
import CryptoTreasures from "../../assets/lib/class.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);
export default function TreasurePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const treasureId = rest.match.params.id;
  const chainId = rest.match.params.chainId;

  // const [treasureInfo, setTreasureInfo] = React.useState(null);
  const [treasure, setTreasure] = useState(null);
  const [openChest, setOpenChest] = useState(true);
  // const [coingeckoTokenList, setCoingeckoTokenList] = React.useState(null);

  const onClickOpenChest = () =>
    openChest ? setOpenChest(false) : setOpenChest(true);
  const [erc20TokensInsideChest, setErc20TokensInsideChest] = useState([]);
  const [DepositModal, setDepositModal] = useState(false);
  const [WithdrawModal, setWithdrawModal] = useState(false);
  const [TransferModal, setTransferModal] = useState(false);
  const [DestroyModal, setDestroyModal] = useState(false);

  const [LockModal, setLockModal] = useState(false);

  const [dateTimeLock, setDateTimeLock] = useState(null);

  const [addressToTransferTreasure, setAddressToTransferTreasure] =
    useState(null);

  const [cryptoTreasures, setCryptoTreasures] = useState(null);
  const [cryptoTreasuresAlwaysOn, setCryptoTreasuresAlwaysOn] = useState(null);

  const web3ReactAlwaysOn = useWeb3React("alwaysOn");
  const web3React = useWeb3React();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (web3React.active) {
      setCryptoTreasures(
        new CryptoTreasures(web3React.library.getSigner(), web3React.chainId)
      );
    } else {
      setCryptoTreasures(null);
    }
  }, [web3React]);

  useEffect(() => {
    if (web3ReactAlwaysOn.active) {
      setCryptoTreasuresAlwaysOn(
        new CryptoTreasures(
          web3ReactAlwaysOn.library,
          web3ReactAlwaysOn.chainId
        )
      );
    } else {
      setCryptoTreasuresAlwaysOn(null);
    }
  }, [web3ReactAlwaysOn]);

  function checkIfCorrectChain() {
    if (!cryptoTreasures || !cryptoTreasuresAlwaysOn) {
      displayMessage("Connect your wallet", "warning");
      return false;
    }

    if (
      cryptoTreasures.signer.provider._network.chainId !=
      web3ReactAlwaysOn.chainId
    ) {
      cryptoTreasures.signer.provider.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${web3ReactAlwaysOn.chainId.toString(16)}` }],
      });
      displayMessage(
        "Connect your wallet on the correct network in metamask",
        "warning"
      );
      return false;
    }
    return true;
  }

  // function getTreasureInfo(treasure) {
  //   console.log("getTreasureInfo");
  //   console.log(treasure);
  //   console.log(treasuresTypesInfo[web3ReactAlwaysOn.chainId]);
  //   let type = treasuresTypesInfo[web3ReactAlwaysOn.chainId][treasure.type.id];

  //   // Object.keys(treasuresTypesInfo[web3ReactAlwaysOn.chainId]).map(function (
  //   //   keyName
  //   // ) {
  //   //   if (
  //   //     treasureId >=
  //   //       treasuresTypesInfo[web3ReactAlwaysOn.chainId][keyName].from &&
  //   //     treasureId <= treasuresTypesInfo[web3ReactAlwaysOn.chainId][keyName].to
  //   //   ) {
  //   //     type = treasuresTypesInfo[web3ReactAlwaysOn.chainId][keyName];
  //   //   }
  //   // });

  //   if (!type) return null;

  //   let treasureMetadata = {
  //     external_url:
  //       process.env.PUBLIC_URL +
  //       "/treasure/" +
  //       web3ReactAlwaysOn.chainId +
  //       "/" +
  //       treasureId,
  //     image:
  //       process.env.PUBLIC_URL +
  //       "/img/treasures/" +
  //       web3ReactAlwaysOn.chainId +
  //       "-" +
  //       type.id +
  //       ".jpg",
  //     name: type.name,
  //     attributes: [],
  //     type: type.id,
  //   };

  //   return treasureMetadata;
  // }

  //Get the treasure
  async function retrieveTreasure() {
    console.log(" retrieveTreasure " + treasureId);
    console.log(web3ReactAlwaysOn);
    if (!cryptoTreasuresAlwaysOn) return;

    try {
      var t = await cryptoTreasuresAlwaysOn.getTreasureDetails(treasureId);
      console.log(t);

      t.treasureType = treasuresTypesInfo[web3ReactAlwaysOn.chainId][t.type.id];

      // var tInfo = getTreasureInfo(t);
      // setTreasureInfo(tInfo);
    } catch (e) {
      console.log(JSON.stringify(e));
      displayMessage("Treasure not found on this chain", "warning");
      rest.history.push("/");
      return null;
    }
    if (!t) {
      rest.history.push("/");
      return null;
    }

    // generateTreasureData();
    setTreasure(t);
    let erc20formatted = await mapERC20(t.erc20Balances);
    setErc20TokensInsideChest(erc20formatted);
    console.log("Treasure data");
    console.log(t);
  }

  async function mapERC20(erc20List) {
    if (!erc20List || erc20List == []) return [];
    let erc20ListTemp = [];

    let addresses = "";
    erc20List.forEach(function (erc20) {
      addresses = addresses + "&addresses=" + erc20.address;
    });

    if (addresses == "") return [];

    let chain = null;
    switch (web3ReactAlwaysOn.chainId) {
      // case 1:
      //   chain = "0x1";
      //   break;
      // case 4:
      //   chain = "0x4";
      //   break;
      case 5:
        chain = "0x5";
        break;
      case 137:
        chain = "0x89";
        break;
      // case 80001:
      //   chain = "mumbai";
      //   break;
      default:
        displayMessage("Wallet chain unknown", "error");
    }
    // if (process.env.PUBLIC_URL == "localhost:3000") chain = "rinkeby";

    const response = await axios.get(
      "https://deep-index.moralis.io/api/v2/" +
        "erc20/metadata?chain=" +
        chain +
        addresses,
      {
        headers: {
          "X-API-KEY":
            "4GNFZC0VFXMtQLwWchYtvPgPjGJznGYdBF11AEx162jK1hU8SokCmg8IRhhwz7iR",
        },
      }
    );

    let erc20TokenList = response?.data;

    erc20List.forEach(function (erc20) {
      var token = erc20TokenList?.find(
        (token) => token.address.toLowerCase() === erc20.address.toLowerCase()
      );

      var erc20ToAdd = {
        decimals: token.decimals,
        logoURI: token.logo,
        name: token.name,
        symbol: token.symbol,
        ...erc20,
      };

      erc20ListTemp.push(erc20ToAdd);
    });

    return erc20ListTemp;
  }

  // useEffect(() => {
  //   if (treasure) {

  //   }
  // }, [treasure, coingeckoTokenList]);

  useEffect(() => {
    if (cryptoTreasuresAlwaysOn) {
      retrieveTreasure();
      const interval = setInterval(() => {
        console.log("Refresh Treasure every 15s");
        retrieveTreasure();
      }, 15000);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [cryptoTreasuresAlwaysOn]);

  // React.useEffect(() => {
  //   const interval = setInterval(() => {
  //     retrieveTreasure();
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, []);

  async function transfer() {
    try {
      //validate address todo
      //Verify if correct network
      if (!checkIfCorrectChain()) return;
      await cryptoTreasures.transfer(treasureId, addressToTransferTreasure);
    } catch (e) {
      let error = cryptoTreasures.errorToHuman(e);
      setTransferModal(false);
      return displayMessage(error.message, "error");
    }
    setTransferModal(false);
    return displayMessage(
      "Transfer has been executed, the chest will be updated once the transaction is validated",
      "success"
    );
  }

  function handleTransferInputChange(e) {
    let address = e.target.value;
    //Validate address ? TODO
    setAddressToTransferTreasure(address);
  }

  function handleDateTimeInputChange(value) {
    //can be a string or moment.
    let d = new Date(value);
    d.setDate(d.getDate() + 1);
    if (d.getTime() < new Date().getTime())
      return displayMessage("Select a date in the future", "warning");

    setDateTimeLock(d);
  }

  async function destroy() {
    setDestroyModal(false);
    try {
      if (!checkIfCorrectChain()) return;
      let result = await cryptoTreasures.destroy(
        treasureId,
        0,
        [],
        [],
        web3React.account
      );
      console.log(result);
    } catch (e) {
      let error = cryptoTreasures.errorToHuman(e);
      return displayMessage(error.message, "error");
    }

    return displayMessage(
      "Destroy has been executed, the chest will be updated once the transaction is validated",
      "success"
    );
  }

  async function lock() {
    try {
      if (!checkIfCorrectChain()) return;

      if (!dateTimeLock || dateTimeLock.getTime() < new Date().getTime())
        return displayMessage("Select a date in the future", "warning");

      await cryptoTreasures.lockBox(treasureId, dateTimeLock.getTime() / 1000);
    } catch (e) {
      let error = cryptoTreasures.errorToHuman(e);
      setLockModal(false);
      return displayMessage(error.message, "error");
    }

    setLockModal(false);
    return displayMessage(
      "Lock has been initiated, the chest will be updated once the transaction is validated",
      "success"
    );
  }
  async function changeRestrictions(restrict) {}

  function openModalIfCorrectChain(modal) {
    if (checkIfCorrectChain()) modal(true);
  }
  function openWithdrawModal() {
    if (checkIfCorrectChain()) setWithdrawModal(true);
  }

  const imageClasses = classNames(classes.imgFluid);
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  function displayMessage(text, type) {
    console.log(text);
    enqueueSnackbar(JSON.stringify(text), {
      variant: type,
      resumeHideDuration: "7000",
    });
  }

  var nowInSec = Math.floor(Date.now() / 1000);

  return (
    <div>
      <Header
        color="transparent"
        brand="CryptoTreasures"
        rightLinks={<HeaderLinks chainId={chainId} />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "primary",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/background.jpg").default}
      />

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ minHeight: "500px" }}
      >
        {treasure ? (
          <div>
            {/* style={{ background: "#fff9e4" }} */}
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      {openChest ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/treasures/" +
                            treasure.treasureType.imgAlt +
                            ".jpg"
                          }
                          alt="..."
                          className={imageClasses}
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/img/treasures/" +
                            treasure.treasureType.img +
                            ".jpg"
                          }
                          alt="..."
                          className={imageClasses}
                        />
                      )}
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div className={classes.name}>
                      <h3 className={classes.title}>
                        {treasure.treasureType.name} #{treasureId}
                      </h3>
                      <h6>
                        Owned by{" "}
                        {web3React?.account &&
                        treasure?.owner.toLowerCase() ===
                          web3React.account.toLowerCase() ? (
                          <strong>YOU</strong>
                        ) : (
                          treasure?.owner
                        )}{" "}
                      </h6>
                      {treasure?.destroyed && (
                        <h5>
                          This chest has been destroyed. Pieces are scattered
                          and you can only withdraw what is left.
                        </h5>
                      )}
                      {treasure?.isLocked && (
                        <h5>
                          This chest is locked and nobody can touch it. The key
                          to open it will return on{" "}
                          {new Intl.DateTimeFormat("en-US", {
                            dateStyle: "full",
                            timeStyle: "long",
                          }).format(treasure.lockedUntil * 1000)}
                        </h5>
                      )}
                      <div className={classes.description}>
                        <p>{treasure.treasureType.desc}</p>
                      </div>
                      <Tooltip
                        title="Deposit NFTs or ERC20 inside your treasure"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="primary"
                          disabled={
                            !web3React.active ||
                            treasure?.destroyed ||
                            treasure?.isLocked
                          }
                          onClick={() =>
                            openModalIfCorrectChain(setDepositModal)
                          }
                        >
                          Deposit
                        </Button>
                      </Tooltip>
                      <Dialog
                        classes={{
                          root: classes.center,
                          paper: classes.modal,
                        }}
                        open={DepositModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setDepositModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        {DepositModal && (
                          <DepositComponent
                            setDepositModal={setDepositModal}
                            cryptoTreasures={cryptoTreasures}
                            displayMessage={displayMessage}
                            treasureId={treasureId}
                            treasure={treasure}
                            checkIfCorrectChain={checkIfCorrectChain}
                          ></DepositComponent>
                        )}
                      </Dialog>
                      <Tooltip
                        title="Withdraw NFTs or ERC20 from your treasure"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="primary"
                          disabled={
                            !web3React.active ||
                            web3React.account.toLowerCase() !=
                              treasure?.owner.toLowerCase() ||
                            treasure?.isLocked
                          }
                          onClick={() =>
                            openModalIfCorrectChain(setWithdrawModal)
                          }
                        >
                          Withdraw
                        </Button>
                      </Tooltip>

                      <Dialog
                        classes={{
                          root: classes.center,
                          paper: classes.modal,
                        }}
                        open={WithdrawModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setWithdrawModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        {WithdrawModal && (
                          <WithdrawComponent
                            setWithdrawModal={setWithdrawModal}
                            cryptoTreasures={cryptoTreasures}
                            treasure={treasure}
                            displayMessage={displayMessage}
                            checkIfCorrectChain={checkIfCorrectChain}
                            erc20TokensInsideChest={erc20TokensInsideChest}
                          ></WithdrawComponent>
                        )}
                      </Dialog>
                      <Tooltip
                        title="Transfer your treasure to another address with everything inside including the locked REQ tokens"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="primary"
                          disabled={
                            !web3React.active ||
                            web3React.account.toLowerCase() !=
                              treasure?.owner.toLowerCase() ||
                            treasure?.destroyed
                          }
                          onClick={() =>
                            openModalIfCorrectChain(setTransferModal)
                          }
                        >
                          Transfer
                        </Button>
                      </Tooltip>
                      <Dialog
                        classes={{
                          root: classes.center,
                          paper: classes.modal,
                        }}
                        open={TransferModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setTransferModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}
                        >
                          <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setTransferModal(false)}
                          >
                            <Close className={classes.modalClose} />
                          </IconButton>
                          <h4 className={classes.modalTitle}>
                            Transfer cryptotreasure ownership
                          </h4>
                        </DialogTitle>
                        <DialogContent
                          id="classic-modal-slide-description"
                          className={classes.modalBody}
                        >
                          <CustomInput
                            id="Transfer"
                            inputProps={{
                              placeholder:
                                "To whom would you like to transfer the cryptotreasure?",
                              onChange: (e) => handleTransferInputChange(e),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <Button onClick={transfer} color="success">
                            Transfer
                          </Button>
                        </DialogContent>
                      </Dialog>

                      <Tooltip
                        title="Destroy the treasure to get back your locked REQ and everything inside"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="warning"
                          disabled={
                            !web3React.active ||
                            web3React.account.toLowerCase() !=
                              treasure?.owner.toLowerCase() ||
                            treasure?.destroyed ||
                            treasure.lockedDestructionEnd > nowInSec ||
                            treasure?.isLocked
                          }
                          onClick={() => setDestroyModal(true)}
                        >
                          Destroy
                        </Button>
                      </Tooltip>

                      <Dialog
                        classes={{
                          root: classes.center,
                          paper: classes.modal,
                        }}
                        open={DestroyModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setDestroyModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}
                        >
                          <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setDestroyModal(false)}
                          >
                            <Close className={classes.modalClose} />
                          </IconButton>
                          <h4 className={classes.modalTitle}>
                            Do you really want to destroy your cryptotreasure?
                          </h4>
                        </DialogTitle>
                        <DialogContent
                          id="classic-modal-slide-description"
                          className={classes.modalBody}
                        >
                          <Button onClick={destroy} color="warning">
                            Destroy
                          </Button>
                          <Button onClick={() => setDestroyModal(false)}>
                            Cancel
                          </Button>
                        </DialogContent>
                      </Dialog>

                      <Tooltip
                        title="Lock the chest for a period of time. While a chest is locked, nothing can go in, nothing can go out and the chest can not be destroyed."
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="primary"
                          disabled={
                            !web3React.active ||
                            web3React.account.toLowerCase() !=
                              treasure?.owner.toLowerCase() ||
                            treasure?.destroyed ||
                            treasure?.isLocked
                          }
                          onClick={() => openModalIfCorrectChain(setLockModal)}
                        >
                          Lock
                        </Button>
                      </Tooltip>
                      <Dialog
                        classes={{
                          root: classes.center,
                          paper: classes.modal,
                        }}
                        open={LockModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setLockModal(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}
                        >
                          <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={() => setLockModal(false)}
                          >
                            <Close className={classes.modalClose} />
                          </IconButton>
                          <h4 className={classes.modalTitle}>
                            Locking the cryptotreasure will prevent anyone from
                            depositing/withdrawing/transfering or destroying the
                            chest until the end of the timelock
                          </h4>
                          <Datetime
                            onChange={(value) =>
                              handleDateTimeInputChange(value)
                            }
                            inputProps={{
                              placeholder:
                                "When would you like to unlock the chest?",
                            }}
                          />
                        </DialogTitle>
                        <DialogContent
                          id="classic-modal-slide-description"
                          className={classes.modalBody}
                        >
                          <Button onClick={lock} color="success">
                            Lock
                          </Button>
                          <div style={{ minHeight: "100px" }}></div>
                        </DialogContent>
                      </Dialog>
                      <Tooltip
                        title="Coming soon"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          color="primary"
                          // disabled={
                          //   !web3React.active ||
                          //   web3React.account.toLowerCase() !=
                          //     treasure?.owner.toLowerCase() ||
                          //   treasure?.destroyed
                          // }
                          disabled
                          // onClick={changeRestrictions}
                        >
                          Restrict Access (soon)
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Coming soon"
                        placement="right"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button color="primary" disabled>
                          Move tokens between chests (soon)
                        </Button>
                      </Tooltip>
                      <div>
                        <Tooltip
                          title="Open the chest to view what is inside"
                          placement="right"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Button color="success" onClick={onClickOpenChest}>
                            {openChest ? "Close" : "Open"}
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={3} className={classes.navWrapper}>
                  <h3>ERC20</h3>
                  {openChest && (
                    <Erc20List erc20List={erc20TokensInsideChest}></Erc20List>
                  )}
                  {!openChest && (
                    <p>Open the chest to check if something is inside</p>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={9} className={classes.navWrapper}>
                  <h3>NFTs</h3>
                  {!openChest && (
                    <p>Open the chest to check if there is NFTs inside</p>
                  )}
                  {openChest && (
                    <GridContainer>
                      {treasure?.erc721Balances.map((erc721, index) =>
                        erc721.ids.map((id) => (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            key={erc721.address + " " + id}
                          >
                            <Card style={{ marginTop: 0 }}>
                              <CardBody style={{ padding: 0 }}>
                                <DisplayErc721
                                  address={erc721.address}
                                  id={id}
                                ></DisplayErc721>
                              </CardBody>
                            </Card>
                            <span></span>
                            {/* <img
                            alt="..."
                            src={erc721}
                            className={navImageClasses}
                          /> */}
                          </GridItem>
                        ))
                      )}

                      {/* <GridItem xs={12} sm={12} md={4}>
                        <video
                          autoPlay
                          className={navImageClasses}
                          controlsList="nodownload"
                          loop=""
                          style={{ width: "100%" }}
                          muted
                          playsInline=""
                          poster="https://lh3.googleusercontent.com/xY25wI2TwO1ooXgmrmCkKZJnrpxy0N6avuC_ULhe3_1tTmXAguUcYhegvQ9gLYbLRswFZuQfPji62oZQpUM4z4z1kp6KWTAIoZOnij4"
                          src="https://storage.opensea.io/files/c0bd24d6e1dd518b8f07538576816884.mp4"
                        ></video>
                      </GridItem> */}
                    </GridContainer>
                  )}
                  {treasure?.erc721Balances.length == 0 && (
                    <GridContainer justify="center">
                      There is no NFT in this chest
                    </GridContainer>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          </div>
        ) : (
          <div>
            <GridContainer justify="center" style={{ paddingTop: "80px" }}>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <CircularProgress />
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
