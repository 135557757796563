// import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";

import { InjectedConnector } from "@web3-react/injected-connector";
// import { gnosisSafe, injected } from '../connectors'
// import { NetworkContextName } from '../constants/misc'
import { NetworkConnector } from "@web3-react/network-connector";

export function useActiveWeb3React() {
  const context = useWeb3React();
  const contextNetwork = useWeb3React("alwaysOn");
  return context.active ? context : contextNetwork;
}

export const injected = new InjectedConnector({
  supportedChainIds: [5, 137],
});

var INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
var DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN;

if (typeof DEFAULT_CHAIN === "undefined") {
  DEFAULT_CHAIN = 137;
}

if (typeof INFURA_KEY === "undefined") {
  // throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
  //test
  INFURA_KEY = "da7767284b42484593813ded0af9832d";
}

export const networkConnector = new NetworkConnector({
  urls: {
    // 1: "https://mainnet.infura.io/v3/" + INFURA_KEY,
    // 4: "https://rinkeby.infura.io/v3/" + INFURA_KEY,
    5: "https://goerli.infura.io/v3/" + INFURA_KEY,
    137: "https://polygon-mainnet.infura.io/v3/" + INFURA_KEY,
    // 80001: "https://polygon-mumbai.infura.io/v3/" + INFURA_KEY,
  },
  defaultChainId: DEFAULT_CHAIN,
});

export async function activateAlwaysOn() {
  const { activate, active, connector } = useWeb3React("alwaysOn");
  // const [tried, setTried] = useState(false);
  console.log(useWeb3React("alwaysOn"));
  useEffect(() => {
    if (!active) {
      activate(networkConnector);
    }
  });
}

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const [tried, setTried] = useState(false);

  // then, if that fails, try connecting to an injected connector
  useEffect(() => {
    if (!active && !tried) {
      injected.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      });
    }
  }, [activate, active]);

  // wait until we get confirmation of a connection to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  return tried;
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch((error) => {
          console.error("Failed to activate after chain changed", error);
        });
      };

      const handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch((error) => {
            console.error("Failed to activate after accounts changed", error);
          });
        }
      };

      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
}
