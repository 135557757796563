import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import blockies from "ethereum-blockies";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/components/erc20listStyle.js";
import axios from "axios";

import { useWeb3React } from "@web3-react/core";
//test
import { ethers, BigNumber } from "ethers";
import genericErc20Abi from "../../assets/lib/erc20Abi";
import genericErc721Abi from "../../assets/lib/erc721Abi";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";
import DisplayErc721 from "./DisplayErc721.js";
import Card from "components/Card/Card.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import CardBody from "components/Card/CardBody.js";

import CustomInput from "components/CustomInput/CustomInput.js";

import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles(styles);

function formatId(id) {
  return id.length > 10 ? id.substr(0, 4) + "..." + id.substr(-4) : id;
}

function formatAddress(addr) {
  return addr.substr(0, 5) + "..." + addr.substr(-3);
}

function getURLExplorer(chainId, address) {
  switch (chainId) {
    case 1:
      return `https://etherscan.io/address/${address}`;
    case 4:
      return `https://rinkeby.etherscan.io/address/${address}`;
    case 5:
      return `https://goerli.etherscan.io/address/${address}`;
    case 80001:
      return `https://mumbai.polygonscan.com/address/${address}`;
    default:
      return "";
  }
}

export default function DepositComponent({
  setDepositModal,
  cryptoTreasures,
  displayMessage,
  treasureId,
  treasure,
  checkIfCorrectChain,
}) {
  const classes = useStyles();

  const [checked, setChecked] = useState([]);
  const [erc20InputArray, setErc20InputArray] = useState([]);
  const [assetsToDeposit, setAssetsToDeposit] = useState(null);
  const [assetsWhichNeedsAllowance, setAssetsWhichNeedsAllowance] =
    useState(null);

  const [erc20sInWallet, setErc20sInWallet] = useState(null);
  const [erc721sInWallet, setErc721sInWallet] = useState(null);
  const [allowanceSent, setAllowanceSent] = useState(false);

  const web3React = useWeb3React();

  function chainIdToMoralis(chainId) {
    let chain = null;
    switch (chainId) {
      // case 1:
      //   chain = "0x1";
      //   break;
      // case 4:
      //   chain = "0x4";
      //   break;
      case 5:
        chain = "0x5";
        break;
      case 137:
        chain = "0x89";
        break;
      // case 80001:
      //   chain = "mumbai";
      //   break;
      default:
        displayMessage("Wallet chain unknown", "danger");
    }

    return chain;
  }

  //Retrieve NFTs and ERC20 of user.
  useEffect(() => {
    if (web3React.active) {
      // var tokensTemp = tokensInWalletConnected;

      //HERE RETRIEVE TOKENS in Wallet

      //When test, find if tokens from theses 4 contracts
      // var contract1 = new ethers.Contract("0x345cA3e014Aaf5dcA488057592ee47305D9B3e10", this.abi, this.signer);
      // var myContract = new web3React.Contract(abi, "0x345cA3e014Aaf5dcA488057592ee47305D9B3e10", provider); // web3

      getWalletBalance();
    }

    async function getWalletBalance() {
      var arrayERC20 = [];
      var arrayERC721 = [];

      if (web3React.chainId == "1337") {
        //Add specific erc721 and erc20 watch to test.
        var erc20Contracts = [
          "0x345cA3e014Aaf5dcA488057592ee47305D9B3e10",
          "0x8f0483125FCb9aaAEFA9209D8E9d7b9C8B9Fb90F",
        ];
        var erc721Contracts = [
          "0x30753E4A8aad7F8597332E813735Def5dD395028",
          "0x2C2B9C9a4a25e24B174f26114e8926a9f2128FE4",
        ];

        for (const contractAddress of erc20Contracts) {
          const contract = new ethers.Contract(
            contractAddress,
            genericErc20Abi.default,
            web3React.library.getSigner()
          );
          const amount = (
            await contract.balanceOf(web3React.account)
          ).toString();

          if (amount && amount != "0") {
            arrayERC20.push({ address: contractAddress, amount: amount });
          }
        }

        for (const contractAddress of erc721Contracts) {
          const contract = new ethers.Contract(
            contractAddress,
            genericErc721Abi.default,
            web3React.library.getSigner()
          );
          var balance = (
            await contract.balanceOf(web3React.account)
          ).toString();
          if (balance && balance != "0") {
            for (var i = 0; i < 20; i++) {
              if (balance == 0) break;

              try {
                const owner = await contract.ownerOf(i);

                if (web3React.account === owner) {
                  console.log("FOUNDDDD");
                  var erc721Info = { address: contractAddress, id: i };
                  arrayERC721.push(erc721Info);
                  balance--;
                }
              } catch {
                console.log("token probably don't exist");
              }
            }
          }
        }
      } else {
        await axios
          .get(
            "https://deep-index.moralis.io/api/v2/" +
              web3React.account +
              "/nft?chain=" +
              chainIdToMoralis(web3React.chainId) +
              "&format=decimal",
            {
              headers: {
                "X-API-KEY":
                  "4GNFZC0VFXMtQLwWchYtvPgPjGJznGYdBF11AEx162jK1hU8SokCmg8IRhhwz7iR",
              },
            }
          )
          .then((response) => {
            arrayERC721 = response?.data?.result
              ?.filter(
                // remove the cryptotreasures
                (erc721) =>
                  erc721.token_address.toLowerCase() !==
                    cryptoTreasures.address.toLowerCase() &&
                  erc721.contract_type == "ERC721"
              )
              .map((erc721) => ({
                address: erc721.token_address,
                id: erc721.token_id,
              }));
          });

        //Then get ERC20
        await axios
          .get(
            "https://deep-index.moralis.io/api/v2/" +
              web3React.account +
              "/erc20?chain=" +
              chainIdToMoralis(web3React.chainId) +
              "&format=decimal",
            {
              headers: {
                "X-API-KEY":
                  "4GNFZC0VFXMtQLwWchYtvPgPjGJznGYdBF11AEx162jK1hU8SokCmg8IRhhwz7iR",
              },
            }
          )
          .then((response) => {
            arrayERC20 = response?.data?.map((erc20) => ({
              address: erc20.token_address,
              amount: erc20.balance,
              decimals: erc20.decimals,
              logoURI: erc20.logo,
              name: erc20.name,
              symbol: erc20.symbol,
            }));
          });
      }

      setErc20sInWallet(arrayERC20);
      setErc721sInWallet(arrayERC721);

      // console.log("Tokens in wallet");
      // console.log(tokensTemp);
    }
  }, [web3React]);

  // async function allow(contractAddress, isERC20) {
  //   // console.log("allow" + contractAddress);
  //   // if (!contractAddress) return;
  //   // var result;
  //   // (isErc20 ? result = await cryptoTreasures.approveERC20ToStore(contractAddress):result = await cryptoTreasures.approveERC721ToStore(contractAddress);)

  // }
  async function allowAll() {
    //for each erc721
    //isapprovedfor all

    try {
      if (!checkIfCorrectChain()) return;
      var result = await assetsWhichNeedsAllowance.erc20s.map((erc20) => {
        cryptoTreasures.approveERC20ToStore(erc20.address);
      });
      var result2 = await assetsWhichNeedsAllowance.erc721s.map((erc721) => {
        cryptoTreasures.approveAllERC721ToStore(erc721.address);
      });
    } catch (e) {
      let error = cryptoTreasures.errorToHuman(e);
      return displayMessage(error, "danger");
    }
    setAllowanceSent(true);
    // displayMessage(
    //   "The allowances have been broadcasted, please wait for transaction confirmation before executing the deposit.",
    //   "success"
    // );
  }

  async function depositAll() {
    try {
      if (!checkIfCorrectChain()) return;
      var result = await cryptoTreasures.store(
        treasureId,
        0,
        assetsToDeposit.erc20s,
        assetsToDeposit.erc721s
      );
    } catch (error) {
      let errorMessage = error;
      console.log(error);
      if (error == "no allowance for the tokens") {
        errorMessage =
          "Allowance is not yet validated. Please wait for the transaction to complete or try the allowance again.";
      } else {
        errorMessage = "An error has been encountered";
      }
      return displayMessage(errorMessage, "danger");
    }
    displayMessage(
      "The deposit is being validated, please refresh the page when the transaction complete.",
      "success"
    );
    setDepositModal(false);
  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  async function checkIfAllowanceNeeded(assets) {
    //Check if allowance needed for these contracts
    var assetsWhichNeedsAllowanceTemp = { erc20s: [], erc721s: [], length: 0 };

    await Promise.all(
      assets.erc20s.map(async (erc20, index) => {
        // var erc20 = assets.erc20s[i];
        const erc20contract = new ethers.Contract(
          erc20.address,
          genericErc20Abi.default,
          web3React.library.getSigner()
        );

        const allowance = await erc20contract.allowance(
          web3React.account,
          cryptoTreasures.address
        );

        if (BigNumber.from(erc20.amount).gt(allowance)) {
          assetsWhichNeedsAllowanceTemp.erc20s.push(erc20);
          assetsWhichNeedsAllowanceTemp.length++;
        }
      })
    );

    await Promise.all(
      assets.erc721s.map(async (erc721, index) => {
        const erc721contract = new ethers.Contract(
          erc721.address,
          genericErc721Abi.default,
          web3React.library.getSigner()
        );

        const isApprovedForAll = await erc721contract.isApprovedForAll(
          web3React.account,
          cryptoTreasures.address
        );
        if (!isApprovedForAll) {
          assetsWhichNeedsAllowanceTemp.erc721s.push(erc721);
          assetsWhichNeedsAllowanceTemp.length++;
        }
      })
    );
    setAssetsWhichNeedsAllowance(assetsWhichNeedsAllowanceTemp);
  }

  function nextStepDeposit() {
    if (!checkIfCorrectChain()) return;
    var assets = { erc20s: [], erc721s: [] };

    if (erc20InputArray.length == 0 && checked.length == 0)
      return displayMessage("select at least one token to deposit", "danger");

    erc20InputArray.map((erc20) => {
      //Check if balance is enough
      let amountAvailable = erc20sInWallet.find((element) => {
        return element.address === erc20.address;
      }).amount;

      if (erc20.error) {
        throw displayMessage("Please check the erc20 amounts", "danger");
      }

      if (BigNumber.from(erc20.amount).gt(amountAvailable)) {
        throw displayMessage("Not enough erc20 in wallet", "danger");
      }
      assets.erc20s.push(erc20);
    });
    checked.map((id) => {
      var resultIndex = assets.erc721s.findIndex((obj) => {
        return obj.address === erc721sInWallet[id].address;
      });
      console.log("resultIndex");
      console.log(resultIndex);
      if (resultIndex > -1) {
        assets.erc721s[resultIndex].ids.push(erc721sInWallet[id].id);
      } else {
        assets.erc721s.push({
          address: erc721sInWallet[id].address,
          ids: [erc721sInWallet[id].id],
        });
      }
      console.log("assets");
      console.log(JSON.stringify(assets));
    });

    console.log("assets final");
    console.log(JSON.stringify(assets));
    checkIfAllowanceNeeded(assets);

    //Reset
    setChecked([]);
    setErc20InputArray([]);

    setAssetsToDeposit(assets);
  }

  function handleErc20InputChange(e, i) {
    let target = e.target;
    let address = target.id.split("-")[0];
    let decimals = target.id.split("-")[1];
    let symbol = target.id.split("-")[2];

    let erc20InputArrayTemp = [...erc20InputArray];

    let index = erc20InputArrayTemp.findIndex((element) => {
      return element.address === address;
    });

    if (target.value == "") {
      index != -1 ? erc20InputArrayTemp.splice(index, 1) : null;
    } else {
      try {
        let amount = ethers.utils.parseUnits(target.value, decimals);

        if (amount.gt(erc20sInWallet[i].amount)) {
          index == -1
            ? erc20InputArrayTemp.push({
                address: address,
                amount: "0",
                error: true,
                decimals,
                symbol,
              })
            : (erc20InputArrayTemp[index].error = true);
          erc20sInWallet[i] = {
            ...erc20sInWallet[i],
            error: true,
            errorMessage: "Not enough balance in wallet",
          };
        } else {
          index == -1
            ? erc20InputArrayTemp.push({
                address: address,
                amount: amount.toString(),
                error: false,
                decimals,
                symbol,
              })
            : (erc20InputArrayTemp[index] = {
                ...erc20InputArrayTemp[index],
                amount: amount.toString(),
                error: false,
                decimals,
                symbol,
              });

          erc20sInWallet[i] = {
            ...erc20sInWallet[i],
            error: false,
            errorMessage: "",
            decimals,
            symbol,
          };
        }
      } catch (e) {
        index == -1
          ? erc20InputArrayTemp.push({
              address: address,
              amount: "0",
              error: true,
              decimals,
              symbol,
            })
          : (erc20InputArrayTemp[index].error = true);
        erc20sInWallet[i] = {
          ...erc20sInWallet[i],
          error: true,
          errorMessage: "Must be a number",
          decimals,
          symbol,
        };
      }
    }
    setErc20sInWallet(erc20sInWallet);
    setErc20InputArray(erc20InputArrayTemp);
  }

  function createBlockies(seed) {
    return blockies.create({
      // All options are optional
      seed, // seed used to generate icon data, default: random
      color: "#dfe", // to manually specify the icon color, default: random
      bgcolor: "#aaa", // choose a different background color, default: random
      // size: 10, // width/height of the icon in blocks, default: 8
      // scale: 2, // width/height of each block in pixels, default: 4
      spotcolor: "#000", // each pixel has a 13% chance of being of a third color,
    });
  }

  return (
    <div>
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setDepositModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>

        <h4 className={classes.modalTitle}>
          {assetsToDeposit
            ? "Deposit confirmation"
            : "Deposit several ERC20/NFT at the same time"}
        </h4>
      </DialogTitle>

      {assetsToDeposit ? (
        <div>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {assetsWhichNeedsAllowance?.length > 0 ? (
              <div>
                <h6 className={classes.modalTitle}>
                  Allow CryptoTreasure to transfer your tokens: There is{" "}
                  {assetsWhichNeedsAllowance.length} transactions needed
                </h6>
                {allowanceSent ? (
                  <div>
                    <small>
                      Wait for the transaction to be validated before depositing
                    </small>
                  </div>
                ) : (
                  <Button onClick={(e) => allowAll()} color="success">
                    Allow Transfer
                  </Button>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div>
              {assetsToDeposit.erc20s.length == 0 ? null : (
                <div>
                  <h5 className={classes.modalTitle}>ERC20 to deposit:</h5>
                  <ul>
                    {assetsToDeposit.erc20s.map((erc20, index) => (
                      <li key={index}>
                        {ethers.utils.formatUnits(erc20.amount, erc20.decimals)}{" "}
                        {erc20.symbol}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {assetsToDeposit.erc721s.length == 0 ? null : (
                <div>
                  <h5 className={classes.modalTitle}>ERC721 to deposit:</h5>
                  <ul>
                    {assetsToDeposit.erc721s.map((erc721, index) => (
                      <li key={index}>
                        <Link
                          to={{
                            pathname: getURLExplorer(
                              web3React.chainId,
                              erc721.address
                            ),
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {formatAddress(erc721.address)}
                        </Link>
                        <ul>
                          {erc721.ids.map((id, index2) => (
                            <li key={index2}>
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={id}
                              >
                                <span># {formatId(id)}</span>
                              </Tooltip>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div>
              <h5 className={classes.modalTitle}>
                Deposit of all the tokens selected in a single transaction.
              </h5>
              {/* <small>Validate all allowance before unlocking Deposit</small> */}
              <Button onClick={(e) => depositAll()} color="success">
                Deposit
              </Button>
            </div>
            {/* ))} */}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              onClick={() => setAssetsToDeposit(null)}
              color="danger"
              simple
            >
              Previous
            </Button>
          </DialogActions>
        </div>
      ) : (
        <div>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {treasure?.owner.toLowerCase() !=
              web3React.account.toLowerCase() && (
              <SnackbarContent
                message={<span>You do not own this chest</span>}
                close
                color="warning"
              />
            )}

            <SnackbarContent
              message={
                <span>
                  Note that it might take up to 5min before the NFTs
                  appear/disappear from this list.
                </span>
              }
              close
              color="warning"
            />
            <h4 className={classes.modalTitle}>ERC20 on your address</h4>
            {erc20sInWallet?.map((erc20, index) => (
              <Card key={index}>
                <CardBody>
                  <img
                    src={
                      erc20.logoURI || createBlockies(erc20.address).toDataURL()
                    }
                    alt=""
                    className="circle"
                    style={{
                      height: "30px",
                      margin: "10px",
                      borderRadius: "50%",
                    }}
                  />
                  <span className="">
                    {erc20.name} :{" "}
                    {ethers.utils.formatUnits(erc20.amount, erc20.decimals)}{" "}
                    {erc20.symbol} available
                    {
                      <CustomInput
                        id={
                          erc20.address +
                          "-" +
                          erc20.decimals +
                          "-" +
                          erc20.symbol
                        }
                        inputProps={{
                          placeholder: "How much would you like to deposit?",
                          onChange: (e) => handleErc20InputChange(e, index),
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        labelText={erc20.errorMessage}
                        error={erc20.error}
                      />
                    }
                  </span>
                  {/* </div> */}
                </CardBody>
              </Card>
            ))}
            {erc20sInWallet == null && (
              <div justify="center">
                <CircularProgress />
              </div>
            )}
            {erc20sInWallet?.length == 0 && (
              <div>No ERC20 found in this wallet</div>
            )}

            {/* <Erc20List erc20List={erc20sInWallet}></Erc20List> */}
            <h4 className={classes.modalTitle}>ERC721 on your address</h4>
            {/* <span>{tokensInWalletConnected.erc721s.toString()}</span> */}
            {erc721sInWallet?.map((erc721, index) => (
              <div key={index}>
                <Card>
                  <CardBody
                    style={{
                      backgroundColor: checked.includes(index)
                        ? "#eee"
                        : "white",
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={1} style={{ margin: "auto" }}>
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(index)}
                        />
                      </Grid>
                      <Grid item xs={11} style={{ margin: "auto" }}>
                        <DisplayErc721
                          address={erc721.address}
                          id={erc721.id.toString()}
                        ></DisplayErc721>
                      </Grid>
                    </Grid>
                  </CardBody>
                </Card>
              </div>
            ))}
            {erc721sInWallet == null && (
              <div>
                <CircularProgress />
              </div>
            )}
            {erc721sInWallet?.length == 0 && (
              <div>No NFTs found in this wallet</div>
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              color="success"
              onClick={nextStepDeposit}
              disabled={erc20InputArray.some((e) => e.error)}
            >
              Deposit
            </Button>
            <Button
              onClick={() => setDepositModal(false)}
              color="danger"
              simple
            >
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </div>
  );
}

DepositComponent.propTypes = {
  treasureId: PropTypes.string,
  treasure: PropTypes.object,
  setDepositModal: PropTypes.func,
  cryptoTreasures: PropTypes.object,
  displayMessage: PropTypes.func,
  checkIfCorrectChain: PropTypes.func,
};
