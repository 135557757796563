import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/Style2.js";

const useStyles = makeStyles(styles);

export default function Section3() {
  const classes = useStyles();
  const imageClasses = classNames(classes.imgFluid);
  console.log("imageClasses");
  console.log(imageClasses);

  const faqList = [
    {
      title: "What happens when I transfer my chest?",
      desc: "The new owner will own the chest with everything inside.",
    },
    {
      title: "Is the content of the chest sold when I sell a chest?",
      desc: "If you buy a chest with items in it, ask the owner to lock the chest for a period of time. This way you can be sure the owner won't be able to withdraw items or destroy the chest right before the sale.",
    },
    {
      title: "What if someone sends me something I do not want?",
      desc: "Everyone can deposit in your chest and you are the only one (with approved others) who can withdraw. You can prevent everyone else (except approved others) from depositing in your chest by using the restrict access functionnality (coming soon).",
    },
    {
      title: "What happen if I destroy the chest?",
      desc: "A chest that is destroyed can't receive any new items. You can still withdraw what is inside. If you locked tokens to get it, destroying it will send the tokens back.",
    },
    {
      title: "Why building boxes?",
      desc: "There will be billions of NFTs. Organizing, sorting and transporting NFTs and ERC20 without having to manage hundreds of wallets will be necessary.",
    },
    {
      title: "Why Treasure chests?",
      desc: "Treasure chests is an analogy that is easily understandable for everyone (especially outside of crypto). Most importantly, the myths around treasure chests trigger the child in most of us.",
    },
    {
      title:
        "Interested in using CryptoTreasures within your game or for your project? ",
      desc: "Contact us on discord.",
    },
    {
      title:
        "If I do an approval for my NFT to another contract, what can it do?",
      desc: "Approved third party have the same role as an owner. It will be allowed to withdraw, deposit or destroy your chest.",
    },
    {
      title: "Remind me what I can do with a cryptotreasure?",
      desc: "Lock it, restrict its access (coming soon), destroy it, move objects between chests (coming soon), deposit in, withdraw from and anything an ERC721 could do.",
    },
    {
      title: "What is next?",
      desc: "Interface for Storing ETH and ERC-1155, L2 deployment, DAO, Chests attributes, Airdrop inside the treasure or whatever you come up with.",
    },
  ];

  return (
    <div className={classes.section}>
      <GridContainer justify="center" direction="row">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Treasures DAO</h2>
          <h4 className={classes.description}>
            In order for the experimentation to live by itself, the control of
            the project will be handed over to a DAO. The DAO will be governed
            using treasures tokens, a token generated by rare treasures on
            Ethereum for their owners.
          </h4>
          <div style={{ textAlign: "center" }}>
            <img
              src={process.env.PUBLIC_URL + "/img/treasures/4-2.jpg"}
              alt="..."
              className={imageClasses}
              style={{ maxHeight: "400px" }}
            />
          </div>
        </GridItem>

        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>FAQ</h2>
          {faqList.map((object, i) => (
            <Accordion key={i}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {faqList[i].title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faqList[i].desc}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </GridItem>
      </GridContainer>
    </div>
  );
}
