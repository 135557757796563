/*eslint-disable*/
import React from "react";
import Search from "@material-ui/icons/Search";
// react components for routing our app without refresh
import { useHistory, useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { useCookies } from "react-cookie";

//Ethereum
import { useWeb3React } from "@web3-react/core";
// import { InjectedConnector } from "@web3-react/injected-connector";
import {
  activateAlwaysOn,
  injected,
  networkConnector,
  useEagerConnect,
} from "../../hooks/web3";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  // const { search } = useLocation();
  const { ...rest } = props;
  const [cookies, setCookie] = useCookies(["chainId"]);
  const [chainId, setChainId] = React.useState(
    props?.chainId ? props.chainId : cookies?.chainId ? cookies.chainId : 137
  );
  const [searchTreasure, setSearchTreasure] = React.useState("");

  const routeChange = () => {
    let path = `/treasure/` + chainId + "/" + searchTreasure;
    history.push(path);
  };
  const handleChangeSearchById = (e) => {
    setSearchTreasure(e.target.value);
  };

  const web3React = useWeb3React();
  const web3ReactAlwaysOn = useWeb3React("alwaysOn");

  if (!web3ReactAlwaysOn.active) {
    networkConnector.changeChainId(chainId);
    console.log(props);
    console.log("networkConnector");
    console.log(networkConnector);
    web3ReactAlwaysOn.activate(networkConnector);
    console.log("web3ReactAlwaysOn");
    console.log(web3ReactAlwaysOn);
  }
  // activateAlwaysOn();
  useEagerConnect();

  async function connectToWallet() {
    web3React.activate(injected);
  }

  async function disconnectToWallet() {
    web3React.deactivate(injected);
  }

  // React.useEffect(() => {
  //   if (web3ReactAlwaysOn.active) {
  //     setChainId(web3ReactAlwaysOn.chainId);
  //   }
  // }, [web3ReactAlwaysOn]);

  function displayEthAddress(address) {
    return address.substring(0, 6) + "..." + address.substring(38, 42);
  }

  function handleChangeChainId(e) {
    try {
      web3ReactAlwaysOn.connector.changeChainId(e.target.value);

      setCookie("chainId", e.target.value, { path: "/" });
      let path = `/`;
      history.push(path);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem} style={{ padding: "10px" }}>
        <div>
          <form onSubmit={routeChange}>
            <CustomInput
              // labelText="Username"
              white
              inputRootCustomClasses={classes.inputRootCustomClasses}
              formControlProps={{
                className: classes.formControl,
              }}
              inputProps={{
                onChange: (e) => handleChangeSearchById(e),
                placeholder: "Search by Id",
                inputProps: {
                  "aria-label": "Search",
                  className: classes.searchInput,
                },
              }}
            />
            <Button justIcon round color="white" onClick={routeChange}>
              <Search className={classes.searchIcon} />
            </Button>
          </form>
        </div>
      </ListItem>
      <ListItem className={classes.listItem} style={{ padding: "10px" }}>
        {/* <Link to="/" className={classes.dropdownLink}> */}
        {web3React.account && (
          <Button simple onClick={disconnectToWallet}>
            {displayEthAddress(web3React.account)}
          </Button>
        )}
        {!web3React.account && (
          <Button color="success" onClick={connectToWallet}>
            Connect Metamask
          </Button>
        )}
      </ListItem>
      <ListItem className={classes.listItem} style={{ padding: "10px" }}>
        {/* <Button color="primary" onClick={connectToWallet}>
          {web3ReactAlwaysOn.chainId == 4 ? "Rinkeby" : "Unknown network"}
        
        </Button> */}
        {web3ReactAlwaysOn.chainId && (
          <FormControl>
            {/* <InputLabel id="demo-simple-select-label">Network</InputLabel> */}

            {/* <Button color="primary"> */}
            <Select
              value={web3ReactAlwaysOn.chainId}
              label="Network"
              onChange={handleChangeChainId}
              style={{ color: "white", marginTop: "10px" }}
            >
              <MenuItem value={137}>Polygon</MenuItem>
              <MenuItem value={5}>Goerli (Testnet)</MenuItem>
            </Select>

            {/* </Button> */}
          </FormControl>
        )}
      </ListItem>
    </List>
  );
}
